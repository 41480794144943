
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IldvcmsgYXQge2NvbXBhbnlOYW1lfSIsIlRpdGxlIGRlc2NyaXB0aW9uIjoiVGhpcyBzdW1tYXJ5IGlzIGdlbmVyYXRlZCBieSBBSSB1c2luZyB0aGUgbW9zdCByZWNlbnQgY3VycmVudCBhbmQgcGFzdCBlbXBsb3llZSByZXZpZXdzLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlvFtMO2w7bDtsWZxLcgxIPEg8SD4bmvIHtjb21wYW55TmFtZX1dIiwiVGl0bGUgZGVzY3JpcHRpb24iOiJb4bmu4bipw6zDrMOsxaEgxaHHmseax5ptzIJtzILEg8SDxIPFmcO9w73DvSDDrMOsw6zFoSDEo%2BG6veG6veG6veC4geC4teC5ieG6veG6veG6vcWZxIPEg8SD4bmv4bq94bq94bq9xowgw5%2FDvcO9w70g4bqsw48gx5rHmseaxaHDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnhur3hur3hur0gbcyCw7bDtsO2xaHhua8gxZnhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDDp8eax5rHmsWZxZnhur3hur3hur3guIHguLXguYnhua8gxIPEg8SD4LiB4Li14LmJxowgxqXEg8SDxIPFoeG5ryDhur3hur3hur1tzILGpcaaw7bDtsO2w73DvcO94bq94bq94bq94bq94bq94bq9IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoS5dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlvFtMO2w7bDtsWZxLcgxIPEg8SD4bmvIHtjb21wYW55TmFtZX1dIiwiVGl0bGUgZGVzY3JpcHRpb24iOiJb4bmu4bipw6zDrMOsxaEgxaHHmseax5ptzIJtzILEg8SDxIPFmcO9w73DvSDDrMOsw6zFoSDEo%2BG6veG6veG6veC4geC4teC5ieG6veG6veG6vcWZxIPEg8SD4bmv4bq94bq94bq9xowgw5%2FDvcO9w70g4bqsw48gx5rHmseaxaHDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnhur3hur3hur0gbcyCw7bDtsO2xaHhua8gxZnhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDDp8eax5rHmsWZxZnhur3hur3hur3guIHguLXguYnhua8gxIPEg8SD4LiB4Li14LmJxowgxqXEg8SDxIPFoeG5ryDhur3hur3hur1tzILGpcaaw7bDtsO2w73DvcO94bq94bq94bq94bq94bq94bq9IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoS5dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    