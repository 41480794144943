import type { LoaderFunction } from 'react-router';

import type { RouteProps } from '../../../shared/RouteProps';
import type { JobsModel } from './jobsModel';

const extractCompanyIdFromSlug = (input?: string): string | undefined => {
  if (!input) {
    return undefined;
  }
  const parts = input.split('-');
  return parts[parts.length - 1];
};

export const jobsLoader =
  ({ services, logger }: RouteProps): LoaderFunction =>
  async ({ params, request }): Promise<JobsModel> => {
    const companyId = extractCompanyIdFromSlug(params.companySlug);

    if (companyId === undefined) {
      throw new Error('Could not find companyId from params', params);
    }

    try {
      const jobs = await services.companyProfileService.getJobs(companyId, 1);

      const url = new URL(request.url);
      const isDraft = url.searchParams.get('draft');

      const companyProfile =
        await services.companyProfileService.getCompanyProfile(
          companyId,
          Boolean(isDraft),
        );

      return {
        jobs: jobs.jobs,
        totalJobs: jobs.totalJobs,
        companyName: companyProfile.name,
        foreignCompanyId: companyProfile.foreignCompanyId,
        companyId,
      };
    } catch (error) {
      logger.error({ error }, 'Error in jobsLoader');
      throw error;
    }
  };
