import type { Branding } from '@seek/libs-shared';
import type { MonthPicker } from 'braid-design-system';
import type { ReviewFormContext } from '../shared/tracking/writeAReview/trackingEvents';
import type { Rating } from './components/StarSelector/StarSelector';
import type { SupportedCurrency } from './questions/GroupedQuestions/SalaryQuestions/SalaryRanges';

export enum Progress {
  OverallRating = 1,
  YourRole = 2,
  YourExperience = 3,
  YourSalary = 4,
  YourFeedback = 5,
  SubmitSuccess = 6,
}
export type { ReviewFormContext };
export type WorkType = 'Full Time' | 'Part Time' | 'Contract' | 'Casual';

export type MonthPickerValue = Parameters<
  Parameters<typeof MonthPicker>[0]['onChange']
>[0];

export const salaryTypeValues = [
  'Monthly',
  'AnnualPackage',
  'HourlyRate',
] as const;

export type SalaryType = (typeof salaryTypeValues)[number];

export type SalaryRating = 'Below Par' | 'Fair' | 'Generous';

export interface Salary {
  includeBonus?: boolean;
  minimum?: number;
  maximum?: number;
  currency?: SupportedCurrency;
}

export interface ReviewPayload {
  overallRating?: Rating;
  careerOpportunityRating?: Rating;
  diversityRating?: Rating;
  executiveManagementRating?: Rating;
  workEnvironmentRating?: Rating;
  workLifeBalanceRating?: Rating;
  perksAndBenefitsRating?: Rating;
  companyName?: string;
  companyId?: string;
  branding?: Branding;
  organisationId?: string;
  theGoodThings?: string;
  theChallenges?: string;
  workHistoryFrom?: MonthPickerValue;
  workHistoryTo?: MonthPickerValue;
  workHistory?: {
    from?: MonthPickerValue;
    to?: MonthPickerValue;
  };
  isWorkHistoryPrefilled?: boolean;
  isStillWorkHere?: boolean;
  workType?: WorkType;
  jobTitleText?: string;
  jobTitleId?: string;
  title?: string;
  fullLocation?: string;
  locationId?: number;
  salary?: Salary;
  roleId?: string;
  salaryRating?: SalaryRating;
  salaryType?: SalaryType;
  isRecommended?: boolean;
  companyProfilePublished?: boolean;
}

export interface PageProps {
  experimentNumber?: number;
  review: ReviewPayload;
  reviewFormContext: ReviewFormContext;
  pageNumber: Progress;
  setPage: (page: Progress) => void;
  setter: React.Dispatch<React.SetStateAction<ReviewPayload>>;
  onSubmit?: () => void;
  onAlertClosed?: () => void;
}

export interface QuestionProps<T = string> {
  value?: T;
  tone?: 'critical' | 'neutral';
  message?: string;
  onChange: (value: T) => void;
}
