import type { LoaderFunction } from 'react-router';

import { type Reviews, type CompanyProfile } from '@seek/libs-shared';
import type { ReviewsTabModel } from './reviewsTabModel';

import type { RouteProps } from '../../../shared/RouteProps';
import { isLocationBasedReviewRoute } from '../../../shared/utils/isLocationBasedReviewRoute';

const REVIEWS_PER_PAGE = 30;

const mapReviewModel = (
  companyProfile: CompanyProfile,
  reviews: Reviews,
): ReviewsTabModel => ({
  reviewSummary: {
    name: companyProfile.name,
    ratingBreakdown: companyProfile.ratingBreakdown,
    salaryRating: companyProfile.salaryRating,
    employeeRecommendationRating: companyProfile.employeeRecommendationRating,
    companyReviewSummaryBreakdown: companyProfile.companyReviewSummaryBreakdown,
    ...(companyProfile.overallRating
      ? {
          overallRating: {
            average: companyProfile.overallRating?.average,
            count: companyProfile.overallRating?.count,
          },
        }
      : {}),
  },
  reviews: {
    ...reviews,
    companyId: companyProfile.id,
  },
  name: companyProfile.name,
  overview: companyProfile.overview,
  branding: companyProfile.branding,
});

const extractCompanyIdFromSlug = (input?: string): string | undefined => {
  if (!input) {
    return undefined;
  }
  const parts = input.split('-');
  return parts[parts.length - 1];
};

export const reviewsLoader =
  ({ services, config }: RouteProps): LoaderFunction =>
  async ({ params, request }) => {
    const companyId = extractCompanyIdFromSlug(params.companySlug);
    if (companyId === undefined) {
      throw new Error('Could not find companyId from params', params);
    }

    const url = new URL(request.url);
    const isDraft = url.searchParams.get('draft');

    const companyProfile =
      await services.companyProfileService.getCompanyProfile(
        companyId,
        Boolean(isDraft),
      );
    const isLocationRoute: boolean = isLocationBasedReviewRoute({
      locationSlug: params.locationSlug,
      locationId: config.reviewLocation.id,
    });
    const reviews = await services.companyProfileService.getReviews({
      companyId,
      page: 1,
      sortOrder: 'most recent',
      perPage: REVIEWS_PER_PAGE,
      locationId: isLocationRoute ? config.reviewLocation.id : undefined,
    });

    return mapReviewModel(companyProfile, reviews);
  };
