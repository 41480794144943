/* istanbul ignore file */
import { getTranslations } from '@seek/libs-shared';
import type { Language } from '@seek/melways-sites';
import {
  disallowedUrl,
  disallowHtml,
  lengthBetween,
  maxLength,
  notHttpUrl,
  required,
} from '@seek/validators-js';
import translations from './.vocab';
import { badWords } from './validator/badWords';
import { booleanRequired } from './validator/booleanRequired';
import { isSalaryValid } from './validator/isSalaryValid';
import { isWorkHistoryValid } from './validator/isWorkHistoryValid';
import { workHistoryRequired } from './validator/workHistoryRequired';

export const GOOD_THINGS_MAX_LENGTH = 300;
export const CHALLENGES_MAX_LENGTH = 300;
export const TITLE_MAX_LENGTH = 1000;
export const COMPANY_NAME_MAX_LENGTH = 100;
export const JOB_TITLE_MAX_LENGTH = 100;

const t = getTranslations(translations);

export const validationRules = (language: Language) => ({
  overallRating: {
    validators: [required],
    validatorProps: {
      requiredMessage: t(language, 'Rate your overall experience'),
    },
  },
  companyName: {
    validators: [badWords, disallowHtml, maxLength, notHttpUrl, required],
    validatorProps: {
      max: COMPANY_NAME_MAX_LENGTH,
      requiredMessage: t(language, 'Company name is required'),
    },
  },
  fullLocation: {
    validators: [maxLength, required],
    validatorProps: {
      max: 200,
      requiredMessage: t(language, 'Select a location from the list'),
    },
  },
  theGoodThings: {
    validators: [
      badWords,
      disallowedUrl,
      disallowHtml,
      lengthBetween,
      notHttpUrl,
      required,
    ],
    validatorProps: {
      min: 20,
      max: GOOD_THINGS_MAX_LENGTH,
      requiredMessage: t(
        language,
        'Tell us the positive aspects of working at the company',
      ),
    },
  },
  theChallenges: {
    validators: [
      badWords,
      disallowedUrl,
      disallowHtml,
      lengthBetween,
      notHttpUrl,
      required,
    ],
    validatorProps: {
      min: 20,
      max: CHALLENGES_MAX_LENGTH,
      requiredMessage: t(
        language,
        'Tell us the challenging aspects of working at the company',
      ),
    },
  },
  title: {
    validators: [
      badWords,
      disallowedUrl,
      disallowHtml,
      lengthBetween,
      notHttpUrl,
      required,
    ],
    validatorProps: {
      min: 20,
      max: TITLE_MAX_LENGTH,
      requiredMessage: t(language, 'Add a summary of your experience'),
    },
  },
  workHistoryFrom: {
    validators: [workHistoryRequired],
  },
  workHistoryTo: {
    validators: [workHistoryRequired],
  },
  workHistory: {
    validators: [isWorkHistoryValid],
  },
  workType: {
    validators: [required],
    validatorProps: {
      requiredMessage: t(language, 'Select a work type'),
    },
  },
  jobTitleText: {
    validators: [
      badWords,
      disallowedUrl,
      disallowHtml,
      maxLength,
      notHttpUrl,
      notHttpUrl,
      required,
    ],
    validatorProps: {
      requiredMessage: t(language, 'Select a job title from the list'),
      max: JOB_TITLE_MAX_LENGTH,
    },
  },
  salary: {
    validators: [isSalaryValid],
  },
  salaryRating: {
    validators: [required],
    validatorProps: {
      requiredMessage: t(language, 'Make a selection'),
    },
  },
  isRecommended: {
    validators: [booleanRequired],
  },
  salaryType: {
    validators: [required],
    validatorProps: {
      requiredMessage: t(language, 'Make a selection'),
    },
  },
});
