import { ORPHAN_REVIEW_COMPANY_ID, useConfig } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { Actions, Box, Button, Heading, Stack } from 'braid-design-system';
import { useEffect } from 'react';
import { CompanySearchCard } from '../../companyProfiles/components/companySearchCard/CompanySearchCard';
import type { SearchCompanyProfileResult } from '../../shared/services/companyProfileService/types';
import { trackDisplayCompanyInterceptPage } from '../../shared/tracking/writeAReview/trackingEvents';
import type { ReviewFormContext } from '../type';
import translations from './.vocab';

export const CompaniesListPage = ({
  searchCompanyProfileResult,
  handleOnCompanyNameClick,
  handleOnDisambiguationBackClick,
  reviewFormContext,
}: {
  searchCompanyProfileResult: SearchCompanyProfileResult;
  handleOnCompanyNameClick: (index: number) => void;
  handleOnDisambiguationBackClick: () => void;
  reviewFormContext: ReviewFormContext;
}) => {
  const { t } = useTranslations(translations);
  const config = useConfig();

  useEffect(() => {
    trackDisplayCompanyInterceptPage({ config, context: reviewFormContext });
  }, []);
  return (
    <Box
      paddingTop={{ tablet: 'xxlarge', mobile: 'large' }}
      data={{ testid: 'company-list-page' }}
    >
      <Stack space="xxlarge">
        <Heading level="1">{t('Select a company to review')}</Heading>
        <Stack space="large">
          {searchCompanyProfileResult.map((company, index) => {
            if (company.companyId !== ORPHAN_REVIEW_COMPANY_ID) {
              return (
                <CompanySearchCard
                  key={`${company.companyId}-${index}`}
                  logo={company.data.branding?.logoImageUrl}
                  name={company.data.name}
                  onClick={() => handleOnCompanyNameClick(index)}
                />
              );
            }
          })}
        </Stack>
        <Actions>
          <Button onClick={handleOnDisambiguationBackClick}>
            {t('Review a different company')}
          </Button>
        </Actions>
      </Stack>
    </Box>
  );
};
