import { getTranslations, type Zone } from '@seek/libs-shared';
import { CompanyProfilesPage } from '../../../paths';
import type { AppConfig } from '../../../shared/config';
import { getCountryByZone } from '../../../shared/utils/zoneCountryCodeMapping';
import translations from './.vocab';
import {
  createAbsoluteUrl,
  getHostName,
  getSiteFromZoneAndProduct,
  siteConfig,
  type Locale,
} from '@seek/melways-sites';

const t = getTranslations(translations);

export const metaBrand = {
  seek: 'SEEK',
  jobstreet: 'JobStreet',
  jobsdb: 'JobsDB',
} as const satisfies Record<string, string>;

type MetaBrand = (typeof metaBrand)[keyof typeof metaBrand];

export interface MetaDataInput {
  tab?: CompanyProfilesPage;
  companyTitle?: string;
  reviewCount?: number;
  companyProfileImage?: string;
  brandUrl?: string;
  absoluteUrl: string;
  companyProfileZone?: Zone;
}

export interface MetaData {
  title: string;
  description: string;
  companyProfileImage?: string;
  absoluteUrl?: string;
  canonicalUrl?: string;
  alternateUrls?: { locale: Locale; url: string }[];
  shouldDisableIndexing?: boolean;
}

export const getMetaData = (
  config: AppConfig,
  {
    tab,
    companyTitle,
    reviewCount = 0,
    companyProfileImage,
    brandUrl,
    absoluteUrl,
    companyProfileZone,
  }: MetaDataInput,
): MetaData => {
  const brand: MetaBrand = metaBrand[config.brand];
  const language = config.language;

  const url = new URL(absoluteUrl);
  const absoluteUrlWithoutQueryParams = `${url.origin}${url.pathname}`;

  let canonicalUrl = absoluteUrlWithoutQueryParams;
  const alternateUrls: { locale: Locale; url: string }[] = [];
  if (companyProfileZone) {
    const site = getSiteFromZoneAndProduct(companyProfileZone, 'candidate');
    const host = getHostName({
      site,
      staging: config.environment !== 'production',
    });
    canonicalUrl = `${host.startsWith('http') ? '' : `${url.protocol}//`}${host}${url.pathname}`;
  }

  const country =
    (companyProfileZone && getCountryByZone(companyProfileZone)) ??
    config.country;
  const site = getSiteFromZoneAndProduct(
    companyProfileZone ?? config.zone,
    'candidate',
  );

  const siteConfiguration = siteConfig[site];

  // remove any language path from the url
  let pathWithNoLanguage = url.pathname;
  siteConfiguration?.languages?.forEach((lang) => {
    pathWithNoLanguage = pathWithNoLanguage.replace(`/${lang}`, '');
  });

  siteConfiguration?.languages?.forEach((lang) => {
    if (lang !== 'zh') {
      alternateUrls.push({
        locale: `${lang}-${country.toUpperCase()}` as Locale,
        url: createAbsoluteUrl({
          product: 'candidate',
          country,
          path: pathWithNoLanguage,
          staging: config.environment !== 'production',
          language: lang,
        }),
      });
    }
  });

  switch (tab) {
    case CompanyProfilesPage.About:
      return {
        title: t(
          language,
          'Working at {companyTitle} company profile and information | {brand}',
          { companyTitle, brand },
        ),
        description: t(
          language,
          'Considering a career at {companyTitle}? Learn what its like to work for {companyTitle} by reading employee ratings and reviews on {brandUrl}',
          { companyTitle, brandUrl },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        canonicalUrl,
        alternateUrls,
      };
    case CompanyProfilesPage.Culture:
      return {
        title: t(
          language,
          '{companyTitle} work culture, perks & benefits | {brand}',
          { companyTitle, brand },
        ),
        description: t(
          language,
          "Learn what it's like to work at {companyTitle} by reading up on {companyTitle}'s culture, benefits & approach to diversity - all on {brand} Company Reviews.",
          { companyTitle, brand },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        canonicalUrl,
        alternateUrls,
      };
    case CompanyProfilesPage.Jobs:
      return {
        title: t(
          language,
          '{companyTitle} job openings and vacancies | {brand}',
          { companyTitle, brand },
        ),
        description: t(
          language,
          'View all current job openings at {companyTitle} here on {brandUrl}',
          { companyTitle, brandUrl },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        canonicalUrl,
        alternateUrls,
      };
    case CompanyProfilesPage.Reviews:
      return {
        title: t(
          language,
          'Reviews {companyTitle} employee ratings and reviews | {brand}',
          { companyTitle, brand },
        ),
        description: `${t(language, 'Read {reviewCount}', {
          reviewCount: reviewCount.toLocaleString(),
        })} ${
          reviewCount > 1 ? t(language, 'reviews') : t(language, 'review')
        } ${t(
          language,
          'for {companyTitle}. Real reviews by real company employee past and present here on {brandUrl}',
          { companyTitle, brandUrl },
        )}`,
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        canonicalUrl,
        alternateUrls,
      };
    default:
      return {
        title: t(language, 'Company Profiles and Reviews | {brand}', { brand }),
        description: t(
          language,
          'Search ratings, reviews and jobs for almost every company here on {brandUrl}',
          { brandUrl },
        ),
        companyProfileImage,
        absoluteUrl: absoluteUrlWithoutQueryParams,
        canonicalUrl,
        alternateUrls,
      };
  }
};
