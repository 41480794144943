import {
  gql,
  type ApolloClient,
  type NormalizedCacheObject,
} from '@apollo/client';
import {
  getByID as getLocationById,
  type LocationLanguage,
} from '@seek/location-data';

import type {
  MkLocationSuggestion,
  QueryMkLocationSuggestionsArgs,
} from '@seek/ca-graphql-schema/types';
import type { AppConfig } from '../../config';

const LOCATION_SEARCH_QUERY = gql`
  query MKLocationSuggestions(
    $searchText: String!
    $session: String!
    $kind: [String!]!
    $zone: Zone!
    $languageCode: LanguageCodeIso
    $countries: [CountryCodeIso2!]
  ) {
    mkLocationSuggestions(
      searchText: $searchText
      session: $session
      kind: $kind
      zone: $zone
      languageCode: $languageCode
      countries: $countries
    ) {
      text
      id
      countryCode
    }
  }
`;

export type LocationSuggestion = Partial<MkLocationSuggestion>;

export type LocationService = ReturnType<typeof createLocationService>;
type CountriesType = QueryMkLocationSuggestionsArgs['countries'];

export const createLocationService = ({
  client,
  config,
}: {
  client: ApolloClient<NormalizedCacheObject>;
  config: AppConfig;
}) => {
  const getLocations = async (
    query: string,
    sessionId: string,
  ): Promise<LocationSuggestion[]> => {
    const LOCATIONS_TO_SUGGEST = 10;

    // Different location kinds for different zones
    const locationKindDefault = ['Suburb', 'SEEKLocation'];
    const locationKindForJobsDB = ['Suburb', 'SEEKArea'];

    const response = await client.query<
      { mkLocationSuggestions: MkLocationSuggestion[] },
      QueryMkLocationSuggestionsArgs
    >({
      query: LOCATION_SEARCH_QUERY,
      variables: {
        searchText: query,
        session: sessionId,
        count: LOCATIONS_TO_SUGGEST,
        kind:
          config.zone === 'asia-1' || config.zone === 'asia-3'
            ? locationKindForJobsDB
            : locationKindDefault,
        zone: config.zone,
        languageCode: config.language,
        countries: [config.country.toUpperCase()] as CountriesType,
      },
    });

    return response.data?.mkLocationSuggestions ?? [];
  };

  const getLocationContextualName = ({
    locationId,
    language,
  }: {
    locationId?: number;
    language: LocationLanguage;
  }): string | undefined => {
    if (!locationId) {
      return undefined;
    }

    const location = getLocationById(locationId);
    if (!location || location.error) {
      return undefined;
    }

    return location.value.contextualName(
      language,
      location.value.country?.countryCode,
    );
  };

  return { getLocations, getLocationContextualName };
};
