import { isMelwaysCountry } from '@seek/melways-sites';

import {
  list as allLocationList,
  type LocationLanguage,
} from '@seek/location-data';

import { isTargetLocation } from './helpers/isTargetLocation';
import { slugifyLocation } from '../../utils/reviewLocationSlug';
import { getLanguagesByCountryCode } from '../../utils/getLanguagesByCountryCode';

type LocationSlug = Record<string, number>;

const locationSlugFactory = () => {
  let locationSlugs: LocationSlug | undefined;

  const generateLocationSlugs = (): LocationSlug => {
    if (!locationSlugs) {
      locationSlugs = {} as unknown as LocationSlug;
      for (const location of allLocationList) {
        const countryCode = location.country?.countryCode?.toLowerCase();

        if (!countryCode || !isMelwaysCountry(countryCode)) {
          continue;
        }

        if (
          !isTargetLocation({
            locationKind: location.kind,
            country: countryCode,
          })
        ) {
          continue;
        }

        const locationLanguages: LocationLanguage[] =
          getLanguagesByCountryCode(countryCode);

        for (const locationLanguage of locationLanguages) {
          const term = location.contextualName(
            locationLanguage,
            countryCode.toUpperCase(),
          );

          const slug = slugifyLocation({
            locationContextualName: term,
            filteredEncoding: true,
          });

          locationSlugs[slug] = location.shortId;
        }
      }
    }

    return locationSlugs;
  };

  return { generateLocationSlugs };
};

export const createLocationSlugService = () => {
  const factory = locationSlugFactory();
  const locationSlugs = factory.generateLocationSlugs();

  const getLocationIdByLocationSlug = (
    locationSlug: string,
  ): number | undefined => locationSlugs[locationSlug] ?? undefined;

  return { getLocationIdByLocationSlug };
};

export type LocationSlugService = ReturnType<typeof createLocationSlugService>;
