import type { PostCompanyReviewInput } from '@seek/ca-graphql-schema/types';
import { ORPHAN_REVIEW_COMPANY_ID } from '@seek/libs-shared';
import type { Language } from '@seek/melways-sites';
import type { ReviewPayload } from '../../type';

export const mapReview = (
  language: Language,
  review: ReviewPayload,
): PostCompanyReviewInput =>
  ({
    companyId: review.companyId ?? ORPHAN_REVIEW_COMPANY_ID,
    companyName: review.companyName,
    organisationId: review.organisationId,
    languageISO: language,
    details: {
      cons: review.theChallenges,
      pros: review.theGoodThings,
      isCompanyRecommended: review.isRecommended ?? false,
      overallRating: Number(review.overallRating),
      overallExperience: review.title,
    },
    job: {
      history: {
        from: {
          year: review.workHistoryFrom?.year,
          month: review.workHistoryFrom?.month,
        },
        ...(!review.isStillWorkHere && review.workHistoryTo
          ? {
              to: {
                year: review.workHistoryTo.year,
                month: review.workHistoryTo.month,
              },
            }
          : {}),
      },
      isJobTitleAnonymised: false,
      location: {
        fullLocation: review.fullLocation,
        locationId: review.locationId,
      },
      roleTitleId: review.jobTitleId,
      title: review.jobTitleText,
      workType: review.workType,
    },
    salary: {
      includeBonus: review.salary?.includeBonus ?? false,
      rating: review.salaryRating,
      type: review.salaryType,
      minimum: review.salary?.minimum,
      maximum: review.salary?.maximum,
      currency: review.salary?.currency,
    },
    ...(review.perksAndBenefitsRating
      ? {
          perksAndBenefits: { rating: Number(review.perksAndBenefitsRating) },
        }
      : {}),
    ...(review.careerOpportunityRating
      ? {
          careerOpportunity: {
            rating: Number(review.careerOpportunityRating),
          },
        }
      : {}),
    ...(review.diversityRating
      ? {
          diversity: {
            rating: Number(review.diversityRating),
          },
        }
      : {}),
    ...(review.executiveManagementRating
      ? {
          executiveManagement: {
            rating: Number(review.executiveManagementRating),
          },
        }
      : {}),
    ...(review.workEnvironmentRating
      ? {
          workEnvironment: {
            rating: Number(review.workEnvironmentRating),
          },
        }
      : {}),
    ...(review.workLifeBalanceRating
      ? {
          workLifeBalance: {
            rating: Number(review.workLifeBalanceRating),
          },
        }
      : {}),
  }) as PostCompanyReviewInput;
