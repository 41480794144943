import type { LocationKind } from '@seek/location-data';
import type { Country } from '@seek/melways-sites';

export const isTargetLocation = ({
  locationKind,
  country,
}: {
  locationKind: LocationKind;
  country: Country;
}): boolean => {
  // JobsDB HK & TH
  if (country === 'hk' || country === 'th') {
    return locationKind === 'State';
  }

  // JobStreet MY
  if (country === 'my') {
    return locationKind === 'SEEKLocation' || locationKind === 'State';
  }

  // The rest of the zones
  return locationKind === 'SEEKLocation';
};
