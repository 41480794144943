import { Box, IconStar } from 'braid-design-system';
import * as styles from './StarSelector.css';

export const Star = ({
  label,
  isSelected,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: {
  label: string;
  isSelected: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: () => void;
}) => {
  return (
    <Box
      role="button"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      tabIndex={0} // for enable keyboard tab accessibility
      onClick={onClick}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onClick();
        }
      }}
      cursor="pointer"
      aria-label={label} // for enable screen reader accessibility
      className={styles.star}
    >
      <IconStar
        active={isSelected}
        size="fill"
        tone={isSelected ? 'brandAccent' : 'secondary'}
      />
    </Box>
  );
};
