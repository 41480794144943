import { useConfig, type ValidationError } from '@seek/libs-shared';
import { Stack, Text } from 'braid-design-system';

import { useTranslations } from '@vocab/react';
import { useEffect } from 'react';
import {
  trackClickYourFeedbackPageContinue,
  trackClickYourFeedbackRating,
  trackDisplayYourFeedbackPage,
} from '../../shared/tracking/writeAReview/trackingEvents';
import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import type { Rating } from '../components/StarSelector/StarSelector';
import { CareerOpportunityRatingQuestion } from '../questions/RatingQuesions/CareerOpportunityRatingQuestion';
import { DiversityRatingQuestion } from '../questions/RatingQuesions/DiversityRatingQuestion';
import { ExecutiveManagementRatingQuestion } from '../questions/RatingQuesions/ExecutiveManagementRatingQuestion';
import { PerksAndBenefitsRatingQuestion } from '../questions/RatingQuesions/PerksAndBenefitsRatingQuestion';
import { WorkEnvironmentRatingQuestion } from '../questions/RatingQuesions/WorkEnvironmentRatingQuestion';
import { WorkLifeBalanceRatingQuestion } from '../questions/RatingQuesions/WorkLifeBalanceRatingQuestion';
import type { PageProps, ReviewPayload } from '../type';
import translations from './.vocab';

type Props = PageProps & {
  formErrors?: ValidationError[];
  isSubmitFailed: boolean;
  isSubmitting: boolean;
};

export const YourFeedbackPage = ({
  review,
  reviewFormContext,
  setter,
  setPage,
  onSubmit,
  onAlertClosed,
  pageNumber,
  formErrors,
  isSubmitFailed,
  isSubmitting,
}: Props) => {
  const config = useConfig();
  const { t } = useTranslations(translations);

  useEffect(() => {
    trackDisplayYourFeedbackPage({ config, context: reviewFormContext });
  }, []);

  const handleOnChange =
    (updateProperty: keyof ReviewPayload) => (value: Rating) => {
      switch (updateProperty) {
        case 'careerOpportunityRating':
          trackClickYourFeedbackRating({
            config,
            context: reviewFormContext,
            currentPageSubsection: 'career_development',
          });
          break;

        case 'workLifeBalanceRating':
          trackClickYourFeedbackRating({
            config,
            context: reviewFormContext,
            currentPageSubsection: 'work_life_balance',
          });
          break;
        case 'workEnvironmentRating':
          trackClickYourFeedbackRating({
            config,
            context: reviewFormContext,
            currentPageSubsection: 'working_environment',
          });
          break;
        case 'executiveManagementRating':
          trackClickYourFeedbackRating({
            config,
            context: reviewFormContext,
            currentPageSubsection: 'management',
          });
          break;
        case 'perksAndBenefitsRating':
          trackClickYourFeedbackRating({
            config,
            context: reviewFormContext,
            currentPageSubsection: 'perks_and_benefits',
          });
          break;
        case 'diversityRating':
          trackClickYourFeedbackRating({
            config,
            context: reviewFormContext,
            currentPageSubsection: 'diversity_and_equal_opportunity',
          });
          break;
      }

      setter({
        ...review,
        [updateProperty]: value,
      });
    };

  const handleOnContinue = () => {
    trackClickYourFeedbackPageContinue({
      config,
      context: reviewFormContext,
      validation: 'success',
    });

    if (onSubmit) {
      onSubmit();
    }
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  // This should never happen, but if it does, we want to show the user
  const formErrorComponent = formErrors && formErrors.length > 0 && (
    <Stack space="small">
      <Text tone="critical" weight="strong">
        {t('Could not submit review due to the following errors:')}
      </Text>
      {formErrors.map((error) => (
        <Text key={error.fieldName} tone="critical">
          {`${error.fieldName} - ${error.message}`}
        </Text>
      ))}
    </Stack>
  );

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={review?.companyName}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      onAlertClosed={isSubmitFailed ? onAlertClosed : undefined}
      isContinueLoading={isSubmitting}
      testid="ratings-page"
      alertText={t(
        "We can't create your review right now. We're doing our best to fix this. Try refreshing the page or check back later.",
      )}
    >
      <Stack space="large">
        <CareerOpportunityRatingQuestion
          onChange={handleOnChange('careerOpportunityRating')}
          value={review?.careerOpportunityRating}
        />
        <WorkLifeBalanceRatingQuestion
          onChange={handleOnChange('workLifeBalanceRating')}
          value={review?.workLifeBalanceRating}
        />
        <WorkEnvironmentRatingQuestion
          onChange={handleOnChange('workEnvironmentRating')}
          value={review?.workEnvironmentRating}
        />
        <ExecutiveManagementRatingQuestion
          onChange={handleOnChange('executiveManagementRating')}
          value={review?.executiveManagementRating}
        />
        <PerksAndBenefitsRatingQuestion
          onChange={handleOnChange('perksAndBenefitsRating')}
          value={review?.perksAndBenefitsRating}
        />
        <DiversityRatingQuestion
          onChange={handleOnChange('diversityRating')}
          value={review?.diversityRating}
        />
        {formErrorComponent}
      </Stack>
    </ReviewPageLayout>
  );
};
