import type {
  PostCompanyReviewInput,
  PostCompanyReviewPayload,
} from '@seek/ca-graphql-schema/types';
import type { CompanyReviewService } from './companyReviewService';

export const createCompanyReviewServiceMock = (): CompanyReviewService => {
  const submitReview = async (
    _input: PostCompanyReviewInput,
  ): Promise<PostCompanyReviewPayload | undefined> => {
    return {
      reviewId: '123',
    };
  };

  return {
    submitReview,
  };
};
