import type { MkLocationSuggestion } from '@seek/ca-graphql-schema/types';
import type { LocationLanguage } from '@seek/location-data';

import type { LocationService } from './locationService';

export const createLocationServiceMock = (): LocationService => {
  const getLocations = async (
    query: string,
    _sessionId: string,
  ): Promise<MkLocationSuggestion[]> => {
    const locations = [
      {
        id: 4,
        text: 'Bendigo',
        countryCode: 'AU',
      },
      {
        id: 1,
        text: 'Sydney',
        countryCode: 'AU',
      },
      {
        id: 2,
        text: 'Melbourne',
        countryCode: 'AU',
      },
      {
        id: 3,
        text: 'Brisbane',
        countryCode: 'AU',
      },
    ];
    const sortedLocations = locations.sort((a, b) => {
      const aMatches = a.text.toLowerCase() === query.toLowerCase();
      const bMatches = b.text.toLowerCase() === query.toLowerCase();

      if (aMatches && !bMatches) return -1;
      if (!aMatches && bMatches) return 1;
      return 0;
    });
    return sortedLocations as MkLocationSuggestion[];
  };
  const getLocationContextualName = (options: {
    locationId?: number;
    language: LocationLanguage;
  }) => (options.locationId ? 'All Melbourne VIC' : undefined);

  return { getLocations, getLocationContextualName };
};
