import { useEffect } from 'react';
import {Box, Heading, HiddenVisually, Stack} from 'braid-design-system';
import type { LocationLanguage } from '@seek/location-data';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';

import {
  type Reviews,
  useConfig,
  useAppContext,
  type SortOrder,
} from '@seek/libs-shared';

import { trackViewEvents } from '../../../shared/tracking/candidateApp/trackViewEvents';
import type { FlagReviewPayload } from '../../../';
import {
  ReviewsEmptyState,
  ReviewsEmptyStateType,
} from '../../components/companyReviews/reviewsEmptyState/ReviewsEmptyState';
import { ReviewsSection } from '../../components/companyReviews/reviewsSection/ReviewsSection';
import { ReviewsSummarySection } from '../../components/companyReviews/reviewsSummarySection/ReviewsSummarySection';
import { useAuth } from '../../../shared/providers/authProvider';
import type { ReviewsTabModel } from './reviewsTabModel';
import {useTranslations} from "@vocab/react";
import translations from "./.vocab";

interface ReviewsPageProps {
  model?: ReviewsTabModel;
  showReviewReplyNotice: boolean;
  getReviews: ({
    companyId,
    page,
    sortOrder,
    perPage,
  }: {
    companyId: string;
    page: number;
    sortOrder: SortOrder;
    perPage?: number;
  }) => Promise<Reviews>;
  upvoteReview: ({
    reviewId,
    companyId,
  }: {
    reviewId: string;
    companyId: string;
  }) => Promise<void>;
  flagReview: (
    companyId: string,
    reviewId: string,
    payload: FlagReviewPayload,
  ) => Promise<boolean>;
  getLocationContextualName: (options: {
    locationId?: number;
    language: LocationLanguage;
  }) => string | undefined;
  trackWriteAReviewClicked: () => void;
  trackReviewsPaginationClicked: ({
    pageNumber,
  }: {
    pageNumber: number;
  }) => void;
  trackSortOrderChanged: ({ sortOrder }: { sortOrder: SortOrder }) => void;
  trackSignInClicked: () => void;
  trackRegisterClicked: () => void;
}

export function ReviewsPage({
  model,
  getReviews,
  upvoteReview,
  flagReview,
  getLocationContextualName,
  trackWriteAReviewClicked,
  trackReviewsPaginationClicked,
  trackSortOrderChanged,
  trackSignInClicked,
  trackRegisterClicked,
}: ReviewsPageProps) {
  const config = useConfig();
  const { companyId, companyName, ...context } = useAppContext();
  const { authenticationStatus } = useAuth();
  const { t } = useTranslations(translations);

  useEffect(() => {
    if (
      companyId &&
      companyName &&
      authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING
    )
      trackViewEvents.reviewsPage({
        config,
        context: { ...context, companyId, companyName },
      });
  }, [companyId, companyName, authenticationStatus]);

  if (!model?.reviews?.paging?.total || model?.reviews?.paging?.total === 0) {
    return (
      <Box paddingY="xxlarge" paddingX={['medium', 'none']}>
        <ReviewsEmptyState
          companyName={model?.reviewSummary?.name}
          trackWriteAReviewClicked={trackWriteAReviewClicked}
          type={ReviewsEmptyStateType.NO_LIST}
        />
      </Box>
    );
  }

  return (
    <>
      <HiddenVisually>
        <Heading level="1">
          {t('Company name Company Reviews', {
            companyName,
          })}
        </Heading>
      </HiddenVisually>
      <Stack space={['none', 'large']}>
        {model?.reviewSummary && (
          <ReviewsSummarySection
            model={model.reviewSummary}
            trackWriteAReviewClicked={trackWriteAReviewClicked}
          />
        )}
        {model?.reviews && (
          <ReviewsSection
            model={model.reviews}
            getReviews={getReviews}
            upvoteReview={upvoteReview}
            flagReview={flagReview}
            getLocationContextualName={getLocationContextualName}
            trackWriteAReviewClicked={trackWriteAReviewClicked}
            trackReviewsPaginationClicked={trackReviewsPaginationClicked}
            trackSortOrderChanged={trackSortOrderChanged}
            trackSignInClicked={trackSignInClicked}
            trackRegisterClicked={trackRegisterClicked}
            mode="view"
          />
        )}
      </Stack>
    </>
  );
}
