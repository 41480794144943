import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { useAppContext } from '@seek/libs-shared';
import { parse } from 'cookie';
import React, { useEffect, useState } from 'react';
import { useAuth } from './authProvider';
import { getFeatureFlag } from './getFeatureFlag';
import { useService } from './serviceProvider';

type FeatureFlagContextProps =
  | {
      featureFlags: Record<string, unknown>;
      cookieFeatureFlags: Record<string, boolean>;
    }
  | undefined;

type FeatureFlagProviderProps = React.PropsWithChildren & {
  testFeatureFlags?: Record<string, unknown>;
};

const FeatureFlagProviderContext =
  React.createContext<FeatureFlagContextProps>(undefined);

const parseCookies = () => {
  if (typeof document === 'undefined') {
    return {};
  }

  return parse(document.cookie);
};

const getFeatureFlagsFromCookies = (
  cookies: Record<string, string | undefined>,
) => {
  return {
    COMPANY_PROFILES_ADD_DEPTH_AI_GEN:
      cookies.COMPANY_PROFILES_ADD_DEPTH_AI_GEN === 'true',
    COMPANY_PROFILES_ADD_DEPTH_REVIEWS:
      cookies.COMPANY_PROFILES_ADD_DEPTH_REVIEWS === 'true',
  };
};

export const FeatureFlagProvider = ({
  children,
  testFeatureFlags,
}: FeatureFlagProviderProps) => {
  const appContext = useAppContext();
  const services = useService();
  const auth = useAuth();

  const [advertiserId, setAdvertiserId] = useState<string | undefined>();
  const [companyId, setCompanyId] = useState<string | undefined>();
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  const [featureFlags, setFeatureFlags] = useState<
    Record<string, unknown> | undefined
  >(testFeatureFlags);

  const cookies = parseCookies();
  const cookieFeatureFlags = getFeatureFlagsFromCookies(cookies);

  useEffect(() => {
    const updateFlags = async () => {
      let updated = false;
      if (featureFlags === undefined) {
        setFeatureFlags({});
        updated = true;
      }
      if (appContext.companyId && appContext.companyId !== companyId) {
        setCompanyId(appContext.companyId);
        updated = true;
      }
      if (appContext.advertiserId && appContext.advertiserId !== advertiserId) {
        setAdvertiserId(appContext.advertiserId);
        updated = true;
      }

      // If user logged in the call again to get the feature flags
      if (
        auth.authenticationStatus === AUTHENTICATION_TYPES.AUTHENTICATED &&
        !authenticated
      ) {
        setAuthenticated(true);
        updated = true;
      }

      if (updated && (appContext.companyId || appContext.advertiserId)) {
        const flags = await services.companyProfileService.getFeatureFlags({
          companyId: appContext.companyId,
          advertiserId,
        });
        setFeatureFlags(flags);
      }
    };
    // ensure all the context providers are loaded before calling the service
    if (
      appContext &&
      services &&
      auth &&
      // Dont get feature flags if login is pending
      auth.authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING
    ) {
      updateFlags();
    }
  }, [appContext, services, auth]);

  return (
    <FeatureFlagProviderContext.Provider
      value={{ featureFlags: featureFlags ?? {}, cookieFeatureFlags }}
    >
      {children}
    </FeatureFlagProviderContext.Provider>
  );
};

export const useFeatureFlags = () => {
  const context = React.useContext(FeatureFlagProviderContext);

  if (context === undefined) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider',
    );
  }

  return {
    ...context,
    getFeatureFlag: getFeatureFlag(context.featureFlags),
  };
};
