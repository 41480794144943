import { Box, Card, Stack, Text, TextLink } from 'braid-design-system';
import { useMelwaysLink } from '@seek/melways-react';

import { useConfig } from '../../../';
import { fromNow } from '../../../utils/date';
import { jobCard } from './JobCard.css';

interface JobCardProps {
  url: string;
  description: string;
  location: string;
  title: string;
  createdAt: string;
  listingDateDisplay: string;
}

export function JobCard({
  url,
  description,
  location,
  title,
  createdAt,
  listingDateDisplay,
}: JobCardProps) {
  const { language } = useConfig();
  const localize = useMelwaysLink();

  return (
    <Card height="full">
      <Box className={jobCard}>
        <Stack space="medium">
          <Text size="large" maxLines={1}>
            <TextLink
              href={`${localize({
                path: url,
                language,
              })}?cid=company-profile&ref=company-profile`}
              rel="nofollow"
            >
              {title}
            </TextLink>
          </Text>
          <Text size="small" maxLines={1}>
            {location}
          </Text>
          <Text size="small" maxLines={3} tone="secondary">
            {description}
          </Text>
          <Text size="small" tone="secondary">
            {listingDateDisplay || fromNow(createdAt, language)}
          </Text>
        </Stack>
      </Box>
    </Card>
  );
}
