import {Box, Heading, HiddenVisually, Stack} from 'braid-design-system';
import { useEffect } from 'react';
import {
  CultureAndValuesSection,
  PerksAndBenefitsSection,
  GallerySection,
  StatementSection,
  useConfig,
  useAppContext,
  type GalleryPaginationType,
} from '@seek/libs-shared';

import type { CultureModel } from './cultureModel';
import { trackViewEvents } from '../../../shared/tracking/candidateApp/trackViewEvents';
import { NoLifeAndCultureContent } from '../../components/noLifeAndCultureContent/NoLifeAndCultureContent';
import { useAuth } from '../../../shared/providers/authProvider';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import {useTranslations} from "@vocab/react";
import translations from "./.vocab";

export function CulturePage({
  model,
  trackPhotosPaginationClicked,
}: {
  model: CultureModel;
  trackPhotosPaginationClicked: ({
    pageNumber,
    type,
  }: {
    pageNumber: number;
    type: GalleryPaginationType;
  }) => void;
}) {
  const config = useConfig();
  const { companyId, companyName, ...context } = useAppContext();
  const { authenticationStatus } = useAuth();
  const { t } = useTranslations(translations);

  useEffect(() => {
    if (
      companyId &&
      companyName &&
      authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING
    )
      trackViewEvents.culturePage({
        config,
        context: { ...context, companyId, companyName },
      });
  }, [companyId, companyName, authenticationStatus]);

  const hasMinimumFields =
    Boolean(model?.cultureAndValues?.title) ||
    Boolean(model?.perksAndBenefits?.length) ||
    Boolean(model?.customStatement?.title) ||
    Boolean(model?.gallery?.photos?.length);


  return (
      <>
        <HiddenVisually>
          <Heading level="1">
            {t('Life and Culture at company name', {
              companyName,
            })}
          </Heading>
        </HiddenVisually>
        {
          hasMinimumFields ? (
              <Stack space={['none', 'gutter']}>
                {model.cultureAndValues ? (
                    <CultureAndValuesSection mode="view" model={model.cultureAndValues} />
                ) : null}
                {model.perksAndBenefits?.length ? (
                    <PerksAndBenefitsSection mode="view" model={model.perksAndBenefits} />
                ) : null}
                {model.customStatement?.title ? (
                    <StatementSection mode="view" model={model.customStatement} />
                ) : null}
                {model.gallery && model.gallery.photos.length > 0 ? (
                    <GallerySection
                        mode="view"
                        model={model.gallery}
                        trackPhotosPaginationClicked={trackPhotosPaginationClicked}
                    />
                ) : null}
              </Stack>
          ) : (
              <Box paddingTop="xxlarge" paddingX={['medium', 'none']}>
                <NoLifeAndCultureContent companyName={model.companyName} />
              </Box>
          )
        }
      </>
  );
}
