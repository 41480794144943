
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiLguITguLjguJPguIjguLDguYHguJnguLDguJnguLPguK3guIfguITguYzguIHguKPguILguK3guIfguITguLjguJPguYPguKvguYnguYDguJ7guLfguYjguK3guJnguKvguKPguLfguK3guYTguKHguYg%2FIiwiWWVzIjoi4LmD4LiK4LmIIiwiTm8iOiLguYTguKHguYjguYPguIrguYgiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiLguKPguK3guJrguIHguLLguKPguIjguYjguLLguKLguYDguIfguLTguJkiLCJBbm51YWwiOiLguKPguLLguKLguJvguLUiLCJIb3VybHkiOiLguKPguLLguKLguIrguLHguYjguKfguYLguKHguIciLCJNb250aGx5Ijoi4Lij4Liy4Lii4LmA4LiU4Li34Lit4LiZIiwiSG93IHdvdWxkIHlvdSByYXRlIHlvdXIgc2FsYXJ5PyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4leC4seC4p%2BC5gOC4reC4h%2BC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkxvdyI6IuC4leC5iOC4syIsIkF2ZXJhZ2UiOiLguITguYjguLLguYDguInguKXguLXguYjguKIiLCJIaWdoIjoi4Liq4Li54LiHIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiLguITguLjguJPguIjguLDguYHguJnguLDguJnguLPguK3guIfguITguYzguIHguKPguILguK3guIfguITguLjguJPguYPguKvguYnguYDguJ7guLfguYjguK3guJnguKvguKPguLfguK3guYTguKHguYg%2FIiwiWWVzIjoi4LmD4LiK4LmIIiwiTm8iOiLguYTguKHguYjguYPguIrguYgiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiLguKPguK3guJrguIHguLLguKPguIjguYjguLLguKLguYDguIfguLTguJkiLCJBbm51YWwiOiLguKPguLLguKLguJvguLUiLCJIb3VybHkiOiLguKPguLLguKLguIrguLHguYjguKfguYLguKHguIciLCJNb250aGx5Ijoi4Lij4Liy4Lii4LmA4LiU4Li34Lit4LiZIiwiSG93IHdvdWxkIHlvdSByYXRlIHlvdXIgc2FsYXJ5PyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4leC4seC4p%2BC5gOC4reC4h%2BC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkxvdyI6IuC4leC5iOC4syIsIkF2ZXJhZ2UiOiLguITguYjguLLguYDguInguKXguLXguYjguKIiLCJIaWdoIjoi4Liq4Li54LiHIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJBcGFrYWggQW5kYSBtZXJla29tZW5kYXNpa2FuIGJla2VyamEgZGkgc2luaT8iLCJZZXMiOiJZYSIsIk5vIjoiVGlkYWsiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiJQZXJpb2RlIGdhamkiLCJBbm51YWwiOiJQZXIgdGFodW4iLCJIb3VybHkiOiJQZXIgamFtIiwiTW9udGhseSI6IlBlciBidWxhbiIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJCYWdhaW1hbmEgQW5kYSBtZW5pbGFpIGdhamkgQW5kYT8iLCJMb3ciOiJSZW5kYWgiLCJBdmVyYWdlIjoiUmF0YS1yYXRhIiwiSGlnaCI6IlRpbmdnaSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJBcGFrYWggQW5kYSBtZXJla29tZW5kYXNpa2FuIGJla2VyamEgZGkgc2luaT8iLCJZZXMiOiJZYSIsIk5vIjoiVGlkYWsiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiJQZXJpb2RlIGdhamkiLCJBbm51YWwiOiJQZXIgdGFodW4iLCJIb3VybHkiOiJQZXIgamFtIiwiTW9udGhseSI6IlBlciBidWxhbiIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJCYWdhaW1hbmEgQW5kYSBtZW5pbGFpIGdhamkgQW5kYT8iLCJMb3ciOiJSZW5kYWgiLCJBdmVyYWdlIjoiUmF0YS1yYXRhIiwiSGlnaCI6IlRpbmdnaSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiLguITguLjguJPguIjguLDguYHguJnguLDguJnguLPguK3guIfguITguYzguIHguKPguILguK3guIfguITguLjguJPguYPguKvguYnguYDguJ7guLfguYjguK3guJnguKvguKPguLfguK3guYTguKHguYg%2FIiwiWWVzIjoi4LmD4LiK4LmIIiwiTm8iOiLguYTguKHguYjguYPguIrguYgiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiLguKPguK3guJrguIHguLLguKPguIjguYjguLLguKLguYDguIfguLTguJkiLCJBbm51YWwiOiLguKPguLLguKLguJvguLUiLCJIb3VybHkiOiLguKPguLLguKLguIrguLHguYjguKfguYLguKHguIciLCJNb250aGx5Ijoi4Lij4Liy4Lii4LmA4LiU4Li34Lit4LiZIiwiSG93IHdvdWxkIHlvdSByYXRlIHlvdXIgc2FsYXJ5PyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4leC4seC4p%2BC5gOC4reC4h%2BC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkxvdyI6IuC4leC5iOC4syIsIkF2ZXJhZ2UiOiLguITguYjguLLguYDguInguKXguLXguYjguKIiLCJIaWdoIjoi4Liq4Li54LiHIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiLguITguLjguJPguIjguLDguYHguJnguLDguJnguLPguK3guIfguITguYzguIHguKPguILguK3guIfguITguLjguJPguYPguKvguYnguYDguJ7guLfguYjguK3guJnguKvguKPguLfguK3guYTguKHguYg%2FIiwiWWVzIjoi4LmD4LiK4LmIIiwiTm8iOiLguYTguKHguYjguYPguIrguYgiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiLguKPguK3guJrguIHguLLguKPguIjguYjguLLguKLguYDguIfguLTguJkiLCJBbm51YWwiOiLguKPguLLguKLguJvguLUiLCJIb3VybHkiOiLguKPguLLguKLguIrguLHguYjguKfguYLguKHguIciLCJNb250aGx5Ijoi4Lij4Liy4Lii4LmA4LiU4Li34Lit4LiZIiwiSG93IHdvdWxkIHlvdSByYXRlIHlvdXIgc2FsYXJ5PyI6IuC4hOC4uOC4k%2BC5g%2BC4q%2BC5ieC4hOC4sOC5geC4meC4meC5gOC4h%2BC4tOC4meC5gOC4lOC4t%2BC4reC4meC4guC4reC4h%2BC4leC4seC4p%2BC5gOC4reC4h%2BC5gOC4l%2BC5iOC4suC5hOC4q%2BC4o%2BC5iCIsIkxvdyI6IuC4leC5iOC4syIsIkF2ZXJhZ2UiOiLguITguYjguLLguYDguInguKXguLXguYjguKIiLCJIaWdoIjoi4Liq4Li54LiHIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJBcGFrYWggQW5kYSBtZXJla29tZW5kYXNpa2FuIGJla2VyamEgZGkgc2luaT8iLCJZZXMiOiJZYSIsIk5vIjoiVGlkYWsiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiJQZXJpb2RlIGdhamkiLCJBbm51YWwiOiJQZXIgdGFodW4iLCJIb3VybHkiOiJQZXIgamFtIiwiTW9udGhseSI6IlBlciBidWxhbiIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJCYWdhaW1hbmEgQW5kYSBtZW5pbGFpIGdhamkgQW5kYT8iLCJMb3ciOiJSZW5kYWgiLCJBdmVyYWdlIjoiUmF0YS1yYXRhIiwiSGlnaCI6IlRpbmdnaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJBcGFrYWggQW5kYSBtZXJla29tZW5kYXNpa2FuIGJla2VyamEgZGkgc2luaT8iLCJZZXMiOiJZYSIsIk5vIjoiVGlkYWsiLCJZb3VyIHBheSBmcmVxdWVuY3kiOiJQZXJpb2RlIGdhamkiLCJBbm51YWwiOiJQZXIgdGFodW4iLCJIb3VybHkiOiJQZXIgamFtIiwiTW9udGhseSI6IlBlciBidWxhbiIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJCYWdhaW1hbmEgQW5kYSBtZW5pbGFpIGdhamkgQW5kYT8iLCJMb3ciOiJSZW5kYWgiLCJBdmVyYWdlIjoiUmF0YS1yYXRhIiwiSGlnaCI6IlRpbmdnaSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iLCJZZXMiOiJZZXMiLCJObyI6Ik5vIiwiWW91ciBwYXkgZnJlcXVlbmN5IjoiWW91ciBwYXkgZnJlcXVlbmN5IiwiQW5udWFsIjoiQW5udWFsbHkiLCJIb3VybHkiOiJIb3VybHkiLCJNb250aGx5IjoiTW9udGhseSIsIkhvdyB3b3VsZCB5b3UgcmF0ZSB5b3VyIHNhbGFyeT8iOiJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIiwiTG93IjoiTG93IiwiQXZlcmFnZSI6IkF2ZXJhZ2UiLCJIaWdoIjoiSGlnaCJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJbxbTDtsO2w7bHmseax5rGmsaMIMO9w73DvcO2w7bDtseax5rHmiDFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxowgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bip4bq94bq94bq9xZnhur3hur3hur0g4bmvw7bDtsO2IMSDxIPEgyDGksWZw6zDrMOs4bq94bq94bq94LiB4Li14LmJxow%2FXSIsIlllcyI6IlvDneG6veG6veG6vcWhXSIsIk5vIjoiW8ORw7bDtl0iLCJZb3VyIHBheSBmcmVxdWVuY3kiOiJbw53DtsO2x5rHmsWZIMalxIPEg8O9w70gxpLFmeG6veG6vXHMh8eax5rhur3hur3guIHguLXguYnDp8O9w71dIiwiQW5udWFsIjoiW%2BG6rOC4geC4teC5ieC4geC4teC5iceax5rHmsSDxIPEg8aaxprDvcO9w71dIiwiSG91cmx5IjoiW%2BG4qMO2w7bHmseaxZnGmsO9w71dIiwiTW9udGhseSI6IlvhuYLDtsO2w7bguIHguLXguYnhua%2FhuKnGmsO9w73DvV0iLCJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIjoiW%2BG4qMO2w7bFtSDFtcO2w7bHmseaxprGjCDDvcO9w7bDtseax5ogxZnEg8SD4bmv4bq94bq9IMO9w73DtsO2x5rHmsWZIMWhxIPEg8aaxIPEg8WZw73DvT9dIiwiTG93IjoiW8WBw7bDtsO2xbVdIiwiQXZlcmFnZSI6Ilvhuqzhub3hur3hur3FmcSDxIPEo%2BG6veG6vV0iLCJIaWdoIjoiW%2BG4qMOsw6zDrMSj4bipXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJXb3VsZCB5b3UgcmVjb21tZW5kIHdvcmtpbmcgaGVyZSB0byBhIGZyaWVuZD8iOiJbxbTDtsO2w7bHmseax5rGmsaMIMO9w73DvcO2w7bDtseax5rHmiDFmeG6veG6veG6vcOnw7bDtsO2bcyCbcyC4bq94bq94bq94LiB4Li14LmJxowgxbXDtsO2w7bFmcS3w6zDrMOs4LiB4Li14LmJxKMg4bip4bq94bq94bq9xZnhur3hur3hur0g4bmvw7bDtsO2IMSDxIPEgyDGksWZw6zDrMOs4bq94bq94bq94LiB4Li14LmJxow%2FXSIsIlllcyI6IlvDneG6veG6veG6vcWhXSIsIk5vIjoiW8ORw7bDtl0iLCJZb3VyIHBheSBmcmVxdWVuY3kiOiJbw53DtsO2x5rHmsWZIMalxIPEg8O9w70gxpLFmeG6veG6vXHMh8eax5rhur3hur3guIHguLXguYnDp8O9w71dIiwiQW5udWFsIjoiW%2BG6rOC4geC4teC5ieC4geC4teC5iceax5rHmsSDxIPEg8aaxprDvcO9w71dIiwiSG91cmx5IjoiW%2BG4qMO2w7bHmseaxZnGmsO9w71dIiwiTW9udGhseSI6IlvhuYLDtsO2w7bguIHguLXguYnhua%2FhuKnGmsO9w73DvV0iLCJIb3cgd291bGQgeW91IHJhdGUgeW91ciBzYWxhcnk%2FIjoiW%2BG4qMO2w7bFtSDFtcO2w7bHmseaxprGjCDDvcO9w7bDtseax5ogxZnEg8SD4bmv4bq94bq9IMO9w73DtsO2x5rHmsWZIMWhxIPEg8aaxIPEg8WZw73DvT9dIiwiTG93IjoiW8WBw7bDtsO2xbVdIiwiQXZlcmFnZSI6Ilvhuqzhub3hur3hur3FmcSDxIPEo%2BG6veG6vV0iLCJIaWdoIjoiW%2BG4qMOsw6zDrMSj4bipXSJ9!"
        )
      )
      });
  
      export { translations as default };
    