import { DEFAULT_LOGO_URL } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import {
  Actions,
  Alert,
  Box,
  Button,
  Heading,
  Hidden,
  IconArrow,
  Inline,
  Spread,
  Stack,
  Step,
  Stepper,
  Text,
} from 'braid-design-system';
import type { Progress } from '../../type';
import translations from './.vocab';
import * as styles from './ReviewPageLayout.css';

type ReviewPageLayoutProps = React.PropsWithChildren<{
  progress?: Progress;
  companyName?: string;
  subtitle?: string;
  logo?: string;
  testid?: string;
  alertText?: string;
  onContinue?: () => void;
  onBack?: () => void;
  onAlertClosed?: () => void;
  isContinueLoading?: boolean;
}>;

export const ReviewPageLayout = ({
  progress = 1,
  companyName,
  subtitle,
  logo,
  children,
  testid,
  alertText,
  onContinue,
  onBack,
  onAlertClosed,
  isContinueLoading = false,
}: ReviewPageLayoutProps) => {
  const { t } = useTranslations(translations);
  return (
    <Box
      data={testid ? { testid } : {}}
      paddingTop={{
        tablet: 'xxlarge',
        mobile: 'large',
      }}
    >
      <Stack space="xlarge">
        {logo && logo !== DEFAULT_LOGO_URL ? (
          <Box>
            <Box
              component="img"
              src={logo}
              alt={t('company logo')}
              className={styles.logoBox}
            />
          </Box>
        ) : null}
        <Stack space="large">
          <Heading level="1">
            {companyName
              ? t('Review working at {companyName}', { companyName })
              : t('Write a company review')}
          </Heading>
          <Stepper label="Review Stepper" progress={progress} align="left">
            <Step>{t('Overall rating')}</Step>
            <Step>{t('Your role')}</Step>
            <Step>{t('Your experience')}</Step>
            <Step>{t('Your salary')}</Step>
            <Step>{t('Your feedback')}</Step>
          </Stepper>
        </Stack>
        {onAlertClosed ? (
          <Alert
            tone="critical"
            onClose={onAlertClosed}
            closeLabel="Close critical alert"
          >
            <Text>{alertText}</Text>
          </Alert>
        ) : null}
        <Box className={styles.questionsBox}>
          <Stack space="xlarge">
            {subtitle ? <Text size="standard">{subtitle}</Text> : null}
            <Box>{children}</Box>
          </Stack>
        </Box>
        <Hidden below="tablet">
          <Spread space="large">
            <Actions>
              {onBack ? (
                <Button
                  variant="soft"
                  tone="neutral"
                  icon={<IconArrow direction="left" />}
                  onClick={onBack}
                  data={{ testid: 'back-button-desktop' }}
                >
                  {t('Back')}
                </Button>
              ) : null}
            </Actions>
            <Actions>
              {onContinue ? (
                <Button
                  variant="solid"
                  tone="formAccent"
                  icon={<IconArrow direction="right" />}
                  iconPosition="trailing"
                  onClick={onContinue}
                  data={{ testid: 'continue-button-desktop' }}
                  loading={isContinueLoading}
                >
                  {t('Continue')}
                </Button>
              ) : null}
            </Actions>
          </Spread>
        </Hidden>
        <Hidden above="mobile">
          <Inline space="medium" collapseBelow="tablet">
            {onContinue ? (
              <Button
                variant="solid"
                tone="formAccent"
                icon={<IconArrow direction="right" />}
                iconPosition="trailing"
                onClick={onContinue}
                data={{ testid: 'continue-button-mobile' }}
                loading={isContinueLoading}
              >
                {t('Continue')}
              </Button>
            ) : null}
            {onBack ? (
              <Button
                variant="soft"
                tone="neutral"
                icon={<IconArrow direction="left" />}
                onClick={onBack}
                data={{ testid: 'back-button-mobile' }}
              >
                {t('Back')}
              </Button>
            ) : null}
          </Inline>
        </Hidden>
      </Stack>
    </Box>
  );
};
