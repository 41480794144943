import { useTranslations } from '@vocab/react';

import { Section } from '@seek/libs-shared';
import { Box, Card, Text } from 'braid-design-system';
import translations from './.vocab';

type AIGenSectionProps = {
  companyName: string;
};

export const AIGenSection = ({ companyName }: AIGenSectionProps) => {
  const { t } = useTranslations(translations);
  const title = t('Title', { companyName });
  return (
    <Section
      heading={title}
      headingBetaBadge={true}
      headingDescription={t('Title description')}
    >
      <Card>
        <Box>
          <Text size="xsmall" tone="secondary">
            AI generated content work in progress ...
          </Text>
        </Box>
      </Card>
    </Section>
  );
};
