import { ORPHAN_REVIEW_COMPANY_ID } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import {
  Autosuggest,
  IconSearch,
  filterSuggestions,
} from 'braid-design-system';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import type { SearchCompanyProfileResult } from '../../../../shared/services/companyProfileService/types';

import type { QuestionProps } from '../../../type';
import { JOB_TITLE_MAX_LENGTH } from '../../../validation/validationRules';

import translations from './.vocab';

export interface CompanyNameValue {
  companyName?: string;
  companyId?: string;
  organisationId?: string;
  published?: boolean;
}

export interface CompanyNameQuestionProps
  extends QuestionProps<CompanyNameValue> {
  text?: string;
  searchCompanyProfiles: (query: string) => Promise<SearchCompanyProfileResult>;
  onValueSelected: () => void;
}

interface AutosuggestValue {
  text: string;
  description?: string;
  value?: string;
  organisationId?: string;
  published?: boolean;
}

export const CompanyNameQuestion = ({
  message = '',
  value = {},
  tone,
  onChange,
  onValueSelected,
  searchCompanyProfiles,
}: CompanyNameQuestionProps) => {
  const MINIMUM_CHARACTERS_SEARCH = 3;
  const [suggestions, setSuggestions] = useState<AutosuggestValue[]>([]);
  const [searchTerm, setSearchTerm] = useState<AutosuggestValue>({
    value: value.companyId,
    text: value.companyName || '',
  });
  const [debouncedSearchTerm] = useDebounce(searchTerm, 400);
  const { t } = useTranslations(translations);

  const handleOnChangeAutosuggest = (event: AutosuggestValue) => {
    setSearchTerm(event);
    if (!event.text || event.text.length < MINIMUM_CHARACTERS_SEARCH) {
      setSuggestions([]);
      onChange({});
      return;
    }
    if (event.text) {
      onValueSelected();
    } else {
      onChange({});
    }
  };

  const onClear = () => {
    setSearchTerm({ value: undefined, text: '' });
    onChange({});
    setSuggestions([]);
  };

  useEffect(() => {
    const fetchCompanies = async (text: string) => {
      const companiesList = await searchCompanyProfiles(text);
      const cannotFindCompany: AutosuggestValue = {
        text: text,
        value: ORPHAN_REVIEW_COMPANY_ID,
        organisationId: undefined,
      };
      const isCompanyFound = companiesList.some(
        (company) => company.data.name.toLowerCase() === text.toLowerCase(),
      );
      const companyOptions = companiesList?.map(
        (company) =>
          ({
            text: company.data.name,
            value: company.companyId,
            organisationId: company.organisationId,
            published: company.data.published,
          }) as AutosuggestValue,
      );
      const companySuggestions = isCompanyFound
        ? companyOptions
        : companyOptions.concat(cannotFindCompany) || [cannotFindCompany];

      setSuggestions(companySuggestions);

      var item = companySuggestions.find(
        (option) => option.text.toLowerCase() === searchTerm.text.toLowerCase(),
      );
      if (item) {
        onChange({
          companyName: item?.text,
          companyId: item?.value,
          organisationId: item?.organisationId,
          published: item?.published,
        });
      } else {
        onChange({
          companyName: cannotFindCompany.text,
          companyId: cannotFindCompany.value,
          organisationId: cannotFindCompany.organisationId,
        });
      }
    };

    const debouncedSearchTermText = debouncedSearchTerm.text?.trim();
    if (
      debouncedSearchTermText &&
      debouncedSearchTermText.length >= MINIMUM_CHARACTERS_SEARCH &&
      debouncedSearchTermText.length <= JOB_TITLE_MAX_LENGTH
    ) {
      fetchCompanies(debouncedSearchTermText);
    }
  }, [debouncedSearchTerm]);

  return (
    <Autosuggest
      id={'companyName'}
      type="text"
      label={t('Company name')}
      aria-label={t('Company name')}
      icon={<IconSearch />}
      onChange={handleOnChangeAutosuggest}
      description={t('Well add this company to your SEEK Profile')}
      suggestions={filterSuggestions(suggestions)}
      onClear={onClear}
      value={searchTerm}
      tone={tone}
      message={message}
      suggestionHighlight="matching"
      reserveMessageSpace
      automaticSelection={
        suggestions[0]?.text.toLowerCase() === searchTerm.text.toLowerCase()
      }
    />
  );
};
