import { useTranslations } from '@vocab/react';
import {
  Actions,
  ButtonLink,
  Card,
  IconArrow,
  Inline,
  Loader,
  Stack,
  Text,
  TextLink,
} from 'braid-design-system';

import {
  buildCommunityGuidelinesURI,
  type CompanyProfile,
  ReviewSummaryBreakdown,
  Section,
  useConfig,
} from '@seek/libs-shared';
import translations from './.vocab';

import {
  ReviewsEmptyState,
  ReviewsEmptyStateType,
} from '../reviewsEmptyState/ReviewsEmptyState';

import { CompanyProfilesPage } from '../../../../';
import { ReviewsSummaryCard } from '../reviewsSummaryCard/ReviewsSummaryCard';

export type ReviewsSummarySectionModel = Pick<
  CompanyProfile,
  | 'companyReviewSummaryBreakdown'
  | 'employeeRecommendationRating'
  | 'name'
  | 'overallRating'
  | 'ratingBreakdown'
  | 'salaryRating'
>;
interface Props {
  loading?: boolean;
  model: ReviewsSummarySectionModel;
  isOnAboutPage?: boolean;
  onActiveTabChanged?: (item: string | undefined) => void;

  trackWriteAReviewClicked: () => void;
  trackReviewsClicked?: () => void;
  link?: string;
}

export function ReviewsSummarySection({
  model,
  isOnAboutPage,
  loading,
  onActiveTabChanged,
  trackWriteAReviewClicked,
  trackReviewsClicked,
  link,
}: Props) {
  const { t } = useTranslations(translations);
  const { locale, zone, experience } = useConfig();
  if (loading || !model) {
    return <Loader />;
  }

  const communityGuidelineURL = buildCommunityGuidelinesURI({
    locale,
    zone,
    experience,
  });
  const hasMinimumReviews = model?.overallRating;

  if (!hasMinimumReviews) {
    return (
      <ReviewsEmptyState
        companyName={model.name}
        wrapInCard
        trackWriteAReviewClicked={trackWriteAReviewClicked}
        type={ReviewsEmptyStateType.NO_SUMMARY}
      />
    );
  }
  return (
    <Section
      loading={loading}
      heading={
        isOnAboutPage
          ? t('Reviews')
          : t('Working at {company_name}', { company_name: model.name })
      }
    >
      <Stack space="large">
        <Stack space="medium">
          <Stack space="small">
            <ReviewsSummaryCard model={model} />
            {!isOnAboutPage && (
              <Card>
                <ReviewSummaryBreakdown
                  model={model.companyReviewSummaryBreakdown}
                />
              </Card>
            )}
          </Stack>
          <Text align="left" size="xsmall">
            {t('Community guideline description', {
              companyName: model.name,
              TextLink: (v: string) => (
                <TextLink href={communityGuidelineURL} rel="nofollow">
                  {v}
                </TextLink>
              ),
            })}
          </Text>
        </Stack>
        {isOnAboutPage && (
          <Actions>
            <Inline space="none">
              <ButtonLink
                href={link || '#'}
                onClick={() => {
                  if (trackReviewsClicked) {
                    trackReviewsClicked();
                  }
                  if (onActiveTabChanged) {
                    onActiveTabChanged(CompanyProfilesPage.Reviews);
                  }
                }}
                icon={<IconArrow direction="right" />}
                iconPosition="trailing"
              >
                {t('See all reviews')}
              </ButtonLink>
            </Inline>
          </Actions>
        )}
      </Stack>
    </Section>
  );
}
