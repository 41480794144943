import {
  Box,
  Column,
  Columns,
  Heading,
  Stack,
  Text,
  Tiles,
} from 'braid-design-system';
import { useTranslations } from '@vocab/react';
import * as styles from './CultureValues.css';
import FittedPhoto from '../../common/FittedPhoto/FittedPhoto';
import translations from './.vocab';

import { Section } from '../../common/section/Section';
import { UpdateSuccessAlert, isEmpty, type CultureAndValues } from '../../../';

interface Props {
  mode: 'view' | 'edit';
  model?: CultureAndValues;
  showUpdateSuccess?: boolean;
  onEditClick?: () => void;
  onHideUpdateSuccess?: () => void;
}

const CoreValuesTextBox = ({
  heading,
  text,
}: {
  heading: string;
  text: string;
}) => (
  <Box className={styles.coreValuesTextBox}>
    <Stack space="gutter">
      <Heading level="3" weight="regular">
        {heading}
      </Heading>
      <Text size="large">{text}</Text>
    </Stack>
  </Box>
);

export function CultureAndValuesSection({
  mode,
  model,
  showUpdateSuccess = false,
  onEditClick,
  onHideUpdateSuccess,
}: Props) {
  const { t } = useTranslations(translations);

  const empty = isEmpty(model as object);

  return (
    <Section
      mode={mode}
      heading={t('Culture and values')}
      dataEmpty={empty}
      addDataHint={t('Highlight the culture and values of your company.')}
      onEditClick={onEditClick}
    >
      <Stack space="xlarge">
        {showUpdateSuccess && (
          <UpdateSuccessAlert
            onClose={() => {
              if (onHideUpdateSuccess) onHideUpdateSuccess();
            }}
            text={t('Culture and values updated')}
          />
        )}
        <Columns alignY="center" space="none" collapseBelow="tablet">
          <Column>
            {model?.imageUrl && (
              <Box className={styles.coreValuesImageBox}>
                <FittedPhoto
                  link={model?.imageUrl}
                  alt={"Company's culture and value information"}
                />
              </Box>
            )}
          </Column>
          <Column width="1/2">
            {model?.title && model?.description && (
              <CoreValuesTextBox
                heading={model?.title}
                text={model?.description}
              />
            )}
          </Column>
        </Columns>
        <Tiles columns={[1, 2, 3]} space={['large', 'xlarge']}>
          {model?.values?.map((value, index) => (
            <Stack key={index} space="medium">
              <Text weight="medium" component="h4">
                {value.title}
              </Text>
              <Text tone="secondary">{value.description}</Text>
            </Stack>
          ))}
        </Tiles>
      </Stack>
    </Section>
  );
}
