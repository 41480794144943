
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0g4Lii4Lix4LiH4LmE4Lih4LmI4Lih4Li14Lir4LiZ4LmJ4Liy4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4LiK4Li14Lin4Li04LiV4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IuC4nuC4muC4geC4seC4muC4guC5ieC4reC4oeC4ueC4peC5g%2BC4q%2BC4oeC5iCDguYYg4LmA4Lij4LmH4LinIOC5hiDguJnguLXguYkiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiLguKvguLLguIHguITguLjguJPguKHguLLguIjguLLguIEge2NvbXBhbnlOYW1lfSA8TGluaz7guJTguLnguILguYnguK3guKHguLnguKXguYDguJ7guLTguYjguKHguYDguJXguLTguKE8L0xpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0g4Lii4Lix4LiH4LmE4Lih4LmI4Lih4Li14Lir4LiZ4LmJ4Liy4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4LiK4Li14Lin4Li04LiV4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IuC4nuC4muC4geC4seC4muC4guC5ieC4reC4oeC4ueC4peC5g%2BC4q%2BC4oeC5iCDguYYg4LmA4Lij4LmH4LinIOC5hiDguJnguLXguYkiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiLguKvguLLguIHguITguLjguJPguKHguLLguIjguLLguIEge2NvbXBhbnlOYW1lfSA8TGluaz7guJTguLnguILguYnguK3guKHguLnguKXguYDguJ7guLTguYjguKHguYDguJXguLTguKE8L0xpbms%2BIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gYmVsdW0gbWVtYnVhdCBoYWxhbWFuIEtlaGlkdXBhbiBkYW4gQnVkYXlhLiIsIkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiOiJDZWsga2VtYmFsaSB1bnR1ayBrb250ZW4gbGFpbiEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJKaWthIEFuZGEgZGFyaSB7Y29tcGFueU5hbWV9LCA8TGluaz5wZWxhamFyaSBzZWxlbmdrYXBueWEuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gYmVsdW0gbWVtYnVhdCBoYWxhbWFuIEtlaGlkdXBhbiBkYW4gQnVkYXlhLiIsIkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiOiJDZWsga2VtYmFsaSB1bnR1ayBrb250ZW4gbGFpbiEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJKaWthIEFuZGEgZGFyaSB7Y29tcGFueU5hbWV9LCA8TGluaz5wZWxhamFyaSBzZWxlbmdrYXBueWEuPC9MaW5rPiJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0g4Lii4Lix4LiH4LmE4Lih4LmI4Lih4Li14Lir4LiZ4LmJ4Liy4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4LiK4Li14Lin4Li04LiV4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IuC4nuC4muC4geC4seC4muC4guC5ieC4reC4oeC4ueC4peC5g%2BC4q%2BC4oeC5iCDguYYg4LmA4Lij4LmH4LinIOC5hiDguJnguLXguYkiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiLguKvguLLguIHguITguLjguJPguKHguLLguIjguLLguIEge2NvbXBhbnlOYW1lfSA8TGluaz7guJTguLnguILguYnguK3guKHguLnguKXguYDguJ7guLTguYjguKHguYDguJXguLTguKE8L0xpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0g4Lii4Lix4LiH4LmE4Lih4LmI4Lih4Li14Lir4LiZ4LmJ4Liy4LmA4LiB4Li14LmI4Lii4Lin4LiB4Lix4Lia4LiK4Li14Lin4Li04LiV4LmB4Lil4Liw4Lin4Lix4LiS4LiZ4LiY4Lij4Lij4Lih4LiB4Liy4Lij4LiX4Liz4LiH4Liy4LiZIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IuC4nuC4muC4geC4seC4muC4guC5ieC4reC4oeC4ueC4peC5g%2BC4q%2BC4oeC5iCDguYYg4LmA4Lij4LmH4LinIOC5hiDguJnguLXguYkiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiLguKvguLLguIHguITguLjguJPguKHguLLguIjguLLguIEge2NvbXBhbnlOYW1lfSA8TGluaz7guJTguLnguILguYnguK3guKHguLnguKXguYDguJ7guLTguYjguKHguYDguJXguLTguKE8L0xpbms%2BIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gYmVsdW0gbWVtYnVhdCBoYWxhbWFuIEtlaGlkdXBhbiBkYW4gQnVkYXlhLiIsIkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiOiJDZWsga2VtYmFsaSB1bnR1ayBrb250ZW4gbGFpbiEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJKaWthIEFuZGEgZGFyaSB7Y29tcGFueU5hbWV9LCA8TGluaz5wZWxhamFyaSBzZWxlbmdrYXBueWEuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gYmVsdW0gbWVtYnVhdCBoYWxhbWFuIEtlaGlkdXBhbiBkYW4gQnVkYXlhLiIsIkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiOiJDZWsga2VtYmFsaSB1bnR1ayBrb250ZW4gbGFpbiEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJKaWthIEFuZGEgZGFyaSB7Y29tcGFueU5hbWV9LCA8TGluaz5wZWxhamFyaSBzZWxlbmdrYXBueWEuPC9MaW5rPiJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Intjb21wYW55TmFtZX0gaGFzbuKAmXQgY3JlYXRlZCBhIExpZmUgYW5kIEN1bHR1cmUgcGFnZSB5ZXQuIiwiQ2hlY2sgYmFjayBzb29uIGZvciBjb250ZW50ISI6IkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJJZiB5b3XigJlyZSBmcm9tIHtjb21wYW55TmFtZX0sIDxMaW5rPmZpbmQgb3V0IG1vcmUuPC9MaW5rPiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Ilt7Y29tcGFueU5hbWV9IOG4qcSDxIPEg8Wh4LiB4Li14LmJ4oCZ4bmvIMOnxZnhur3hur3hur3Eg8SDxIPhua%2Fhur3hur3hur3GjCDEg8SDxIMgxYHDrMOsw6zGkuG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDDh8eax5rHmsaa4bmvx5rHmseaxZnhur3hur3hur0gxqXEg8SDxIPEo%2BG6veG6veG6vSDDvcO9w73hur3hur3hur3hua8uXSIsIkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiOiJbw4fhuKnhur3hur3hur3Dp8S3IMOfxIPEg8SDw6fEtyDFocO2w7bDtsO2w7bDtuC4geC4teC5iSDGksO2w7bDtsWZIMOnw7bDtsO24LiB4Li14LmJ4bmv4bq94bq94bq94LiB4Li14LmJ4bmvIV0iLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJbw4%2FGkiDDvcO9w73DtsO2w7bHmseax5rigJnFmeG6veG6veG6vSDGksWZw7bDtsO2bcyCIHtjb21wYW55TmFtZX0sIDxMaW5rPsaSw6zDrMOs4LiB4Li14LmJxowgw7bDtsO2x5rHmsea4bmvIG3MgsO2w7bDtsWZ4bq94bq94bq9LjwvTGluaz5dIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJ7Y29tcGFueU5hbWV9IGhhc27igJl0IGNyZWF0ZWQgYSBMaWZlIGFuZCBDdWx0dXJlIHBhZ2UgeWV0LiI6Ilt7Y29tcGFueU5hbWV9IOG4qcSDxIPEg8Wh4LiB4Li14LmJ4oCZ4bmvIMOnxZnhur3hur3hur3Eg8SDxIPhua%2Fhur3hur3hur3GjCDEg8SDxIMgxYHDrMOsw6zGkuG6veG6veG6vSDEg8SDxIPguIHguLXguYnGjCDDh8eax5rHmsaa4bmvx5rHmseaxZnhur3hur3hur0gxqXEg8SDxIPEo%2BG6veG6veG6vSDDvcO9w73hur3hur3hur3hua8uXSIsIkNoZWNrIGJhY2sgc29vbiBmb3IgY29udGVudCEiOiJbw4fhuKnhur3hur3hur3Dp8S3IMOfxIPEg8SDw6fEtyDFocO2w7bDtsO2w7bDtuC4geC4teC5iSDGksO2w7bDtsWZIMOnw7bDtsO24LiB4Li14LmJ4bmv4bq94bq94bq94LiB4Li14LmJ4bmvIV0iLCJJZiB5b3XigJlyZSBmcm9tIGNvbXBhbnksIGZpbmQgb3V0IG1vcmUiOiJbw4%2FGkiDDvcO9w73DtsO2w7bHmseax5rigJnFmeG6veG6veG6vSDGksWZw7bDtsO2bcyCIHtjb21wYW55TmFtZX0sIDxMaW5rPsaSw6zDrMOs4LiB4Li14LmJxowgw7bDtsO2x5rHmsea4bmvIG3MgsO2w7bDtsWZ4bq94bq94bq9LjwvTGluaz5dIn0%3D!"
        )
      )
      });
  
      export { translations as default };
    