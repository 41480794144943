import type { Country, Language } from '@seek/melways-sites';

export const getLanguagesByCountryCode = (
  countryCode: Country,
): //* We Do Not support 'zh' language from Melways yet.
Exclude<Language, 'zh'>[] => {
  switch (countryCode) {
    case 'th':
      return ['en', 'th'];
    case 'id':
      return ['en', 'id'];
    default:
      return ['en'];
  }
};
