
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4pyB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoi4Lij4Li14Lin4Li04Lin4Lil4LmI4Liy4Liq4Li44LiUIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IuC4hOC4sOC5geC4meC4meC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC4iOC4sOC5geC4quC4lOC4h%2BC4leC4suC4oeC4hOC4sOC5geC4meC4meC4iOC4o%2BC4tOC4h%2BC4iOC4suC4geC4nuC4meC4seC4geC4h%2BC4suC4mSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguYfguJnguYTguJvguJXguLLguKE8VGV4dExpbms%2B4LmB4LiZ4Lin4LiX4Liy4LiH4Lib4LiP4Li04Lia4Lix4LiV4Li04LiC4Lit4LiH4LiK4Li44Lih4LiK4LiZPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4pyB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoi4Lij4Li14Lin4Li04Lin4Lil4LmI4Liy4Liq4Li44LiUIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IuC4hOC4sOC5geC4meC4meC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC4iOC4sOC5geC4quC4lOC4h%2BC4leC4suC4oeC4hOC4sOC5geC4meC4meC4iOC4o%2BC4tOC4h%2BC4iOC4suC4geC4nuC4meC4seC4geC4h%2BC4suC4mSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguYfguJnguYTguJvguJXguLLguKE8VGV4dExpbms%2B4LmB4LiZ4Lin4LiX4Liy4LiH4Lib4LiP4Li04Lia4Lix4LiV4Li04LiC4Lit4LiH4LiK4Li44Lih4LiK4LiZPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSJ9!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSJ9!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4pyB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoi4Lij4Li14Lin4Li04Lin4Lil4LmI4Liy4Liq4Li44LiUIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IuC4hOC4sOC5geC4meC4meC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC4iOC4sOC5geC4quC4lOC4h%2BC4leC4suC4oeC4hOC4sOC5geC4meC4meC4iOC4o%2BC4tOC4h%2BC4iOC4suC4geC4nuC4meC4seC4geC4h%2BC4suC4mSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguYfguJnguYTguJvguJXguLLguKE8VGV4dExpbms%2B4LmB4LiZ4Lin4LiX4Liy4LiH4Lib4LiP4Li04Lia4Lix4LiV4Li04LiC4Lit4LiH4LiK4Li44Lih4LiK4LiZPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IuC4o%2BC4teC4p%2BC4tOC4pyB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoi4Lij4Li14Lin4Li04Lin4Lil4LmI4Liy4Liq4Li44LiUIiwiU2VlIGFsbCByZXZpZXdzIjoi4LiU4Li54Lij4Li14Lin4Li04Lin4LiX4Lix4LmJ4LiH4Lir4Lih4LiUIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IuC4hOC4sOC5geC4meC4meC4quC4s%2BC4q%2BC4o%2BC4seC4miB7Y29tcGFueU5hbWV9IOC4iOC4sOC5geC4quC4lOC4h%2BC4leC4suC4oeC4hOC4sOC5geC4meC4meC4iOC4o%2BC4tOC4h%2BC4iOC4suC4geC4nuC4meC4seC4geC4h%2BC4suC4mSDguYDguJ7guLfguYjguK3guYPguKvguYnguYDguJvguYfguJnguYTguJvguJXguLLguKE8VGV4dExpbms%2B4LmB4LiZ4Lin4LiX4Liy4LiH4Lib4LiP4Li04Lia4Lix4LiV4Li04LiC4Lit4LiH4LiK4Li44Lih4LiK4LiZPC9UZXh0TGluaz7guILguK3guIfguYDguKPguLIifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSJ9!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6IlVsYXNhbiB7Y29tcGFueU5hbWV9IiwiU2Vjb25kYXJ5IHRpdGxlIjoiVWxhc2FuIHRlcmJhcnUiLCJTZWUgYWxsIHJldmlld3MiOiJMaWhhdCBzZW11YSB1bGFzYW4iLCJDb21tdW5pdHkgZ3VpZGVsaW5lIGRlc2NyaXB0aW9uIjoiUGVuaWxhaWFuIHVudHVrIHtjb21wYW55TmFtZX0gZGl0YW1waWxrYW4gYXBhIGFkYW55YSBkYXJpIGthcnlhd2FuIHNlc3VhaSBkZW5nYW4gPFRleHRMaW5rPnBlZG9tYW4ga29tdW5pdGFzPC9UZXh0TGluaz4ga2FtaSJ9!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Intjb21wYW55TmFtZX0gcmV2aWV3cyIsIlNlY29uZGFyeSB0aXRsZSI6IlJlY2VudCByZXZpZXdzIiwiU2VlIGFsbCByZXZpZXdzIjoiU2VlIGFsbCByZXZpZXdzIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlJhdGluZ3MgZm9yIHtjb21wYW55TmFtZX0gYXJlIHNoYXJlZCBhcy1pcyBmcm9tIGVtcGxveWVlcyBpbiBsaW5lIHdpdGggb3VyIDxUZXh0TGluaz5jb21tdW5pdHkgZ3VpZGVsaW5lczwvVGV4dExpbms%2BIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Ilt7Y29tcGFueU5hbWV9IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoV0iLCJTZWNvbmRhcnkgdGl0bGUiOiJbxZjhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaFdIiwiU2VlIGFsbCByZXZpZXdzIjoiW8Wg4bq94bq94bq94bq9IMSDxIPGmsaaIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlvFmMSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9IMSDxIPEg8WZ4bq94bq94bq9IMWh4bipxIPEg8SDxZnhur3hur3hur3GjCDEg8SDxIPFoS3DrMOsw6zFoSDGksWZw7bDtsO2bcyCIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSDDrMOsw6zguIHguLXguYkgxprDrMOsw6zguIHguLXguYnhur3hur3hur0gxbXDrMOsw6zhua%2FhuKkgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoTwvVGV4dExpbms%2BXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJUaXRsZSI6Ilt7Y29tcGFueU5hbWV9IMWZ4bq94bq94bq94bm9w6zDrMOs4bq94bq94bq9xbXFoV0iLCJTZWNvbmRhcnkgdGl0bGUiOiJbxZjhur3hur3hur3Dp%2BG6veG6veG6veC4geC4teC5ieG5ryDFmeG6veG6veG6veG5vcOsw6zDrOG6veG6veG6vcW1xaFdIiwiU2VlIGFsbCByZXZpZXdzIjoiW8Wg4bq94bq94bq94bq9IMSDxIPGmsaaIMWZ4bq94bq94bm9w6zDrOG6veG6vcW1xaFdIiwiQ29tbXVuaXR5IGd1aWRlbGluZSBkZXNjcmlwdGlvbiI6IlvFmMSDxIPEg%2BG5r8Osw6zDrOC4geC4teC5icSjxaEgxpLDtsO2w7bFmSB7Y29tcGFueU5hbWV9IMSDxIPEg8WZ4bq94bq94bq9IMWh4bipxIPEg8SDxZnhur3hur3hur3GjCDEg8SDxIPFoS3DrMOsw6zFoSDGksWZw7bDtsO2bcyCIOG6veG6veG6vW3MgsalxprDtsO2w7bDvcO9w73hur3hur3hur3hur3hur3hur3FoSDDrMOsw6zguIHguLXguYkgxprDrMOsw6zguIHguLXguYnhur3hur3hur0gxbXDrMOsw6zhua%2FhuKkgw7bDtsO2x5rHmseaxZkgPFRleHRMaW5rPsOnw7bDtm3Mgm3Mgseax5rguIHguLXguYnDrMOs4bmvw73DvSDEo8eax5rDrMOsxozhur3hur3GmsOsw6zguIHguLXguYnhur3hur3FoTwvVGV4dExpbms%2BXSJ9!"
        )
      )
      });
  
      export { translations as default };
    