import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { Stack } from 'braid-design-system';
import { useEffect } from 'react';
import {
  trackClickYourExperiencePageContinue,
  trackClickYourExperienceSubSection,
  trackDisplayYourExperiencePage,
  trackValidationErrorYourExperiencePage,
} from '../../shared/tracking/writeAReview/trackingEvents';
import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import { RecommendationQuestion } from '../questions/SegmentedButtonQuestions/RecommendationQuestion';
import { TheChallengesQuestion } from '../questions/SimpleQuestions/TheChallengesQuestion';
import { TheGoodThingsQuestion } from '../questions/SimpleQuestions/TheGoodThingsQuestion';
import { TheTitleQuestion } from '../questions/SimpleQuestions/TheTitleQuestion';
import type { PageProps, ReviewPayload } from '../type';
import { mapErrorMessage } from '../utils/mapErrorMessage';
import { validationRules } from '../validation/validationRules';
import translations from './.vocab';

export const YourExperiencePage = ({
  review,
  reviewFormContext,
  setPage,
  setter,
  pageNumber,
}: PageProps) => {
  const { t } = useTranslations(translations);

  const config = useConfig();

  useEffect(() => {
    trackDisplayYourExperiencePage({ config, context: reviewFormContext });
  }, []);

  const fields = {
    theGoodThings: review?.theGoodThings,
    theChallenges: review?.theChallenges,
    title: review?.title,
    isRecommended: review?.isRecommended,
  };

  const { theGoodThings, theChallenges, title, isRecommended } =
    validationRules(config.language);

  const rules = {
    theGoodThings,
    theChallenges,
    title,
    isRecommended,
  } as Rules<typeof fields>;

  const {
    getTone,
    getMessage,
    handleValueChangeWithValidation,
    validateWithErrors,
  } = useValidation<ReviewPayload>(fields, rules);

  const handleOnContinue = async () => {
    const { isValid, errors } = validateWithErrors();

    trackClickYourExperiencePageContinue({
      config,
      context: reviewFormContext,
      validation: isValid ? 'success' : 'mandatory_questions_not_answered',
    });

    if (!isValid) {
      trackValidationErrorYourExperiencePage({
        config,
        context: reviewFormContext,
        errorMessage: errors.map((error) => mapErrorMessage(error.fieldName)),
        errorText: errors.map((error) => error.message),
      });
      return;
    }

    setPage(pageNumber + 1);
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  const onRecommendationChange = (value: boolean) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'isRecommended',
      previousValue: review,
    })(value);

    trackClickYourExperienceSubSection({
      config,
      context: reviewFormContext,
      currentPageSubsection: 'recommend_to_a_friend',
    });
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={review?.companyName}
      subtitle={t(
        'A great review shares details about what works well and what doesnt work well so someone understands the company culture.',
      )}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      testid="your-experience-page"
    >
      <Stack space="small">
        <RecommendationQuestion
          onChange={onRecommendationChange}
          value={review?.isRecommended}
          tone={getTone('isRecommended')}
          message={getMessage('isRecommended')}
        />
        <TheGoodThingsQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'theGoodThings',
            previousValue: review,
          })}
          value={review?.theGoodThings}
          tone={getTone('theGoodThings')}
          message={getMessage('theGoodThings')}
        />
        <TheChallengesQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'theChallenges',
            previousValue: review,
          })}
          value={review?.theChallenges}
          tone={getTone('theChallenges')}
          message={getMessage('theChallenges')}
        />
        <TheTitleQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'title',
            previousValue: review,
          })}
          value={review?.title}
          tone={getTone('title')}
          message={getMessage('title')}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
