import { BrandLogo, CoverImage } from '@seek/branding-assets';

import {
  Bleed,
  Box,
  ButtonLink,
  Column,
  Columns,
  Heading,
  IconEdit,
  Inline,
  Loader,
  Rating,
  Stack,
  Text,
  TextLink,
  TextLinkButton,
} from 'braid-design-system';
import {
  useConfig,
  type CompanyProfile,
  buildWriteAReviewURI,
} from '../../../';
import { useTranslations } from '@vocab/react';

import { Section } from '../../common/section/Section';

import translations from './.vocab';

export type CompanyBrandingSectionModel = Pick<
  CompanyProfile,
  'branding' | 'name' | 'rating' | 'id' | 'zone'
>;

interface Props {
  model?: Omit<CompanyBrandingSectionModel, 'id'>;
  loading?: boolean;
  navigateToReviewsTab: () => void;
  navigateToAboutTab: () => void;
  trackWriteAReviewClicked: () => void;
  aboutPageLink: string;
}

const createImageObject = (propertyName: string, imageUrl?: string) => ({
  url: imageUrl || '',
  strategies: {
    [propertyName]: imageUrl || '',
  },
  id: imageUrl || '',
});

/**
 * This is a view mode component
 */
export function CompanyBrandingSection({
  model,
  loading,
  navigateToReviewsTab,
  navigateToAboutTab,
  trackWriteAReviewClicked,
  aboutPageLink,
}: Props) {
  const { t } = useTranslations(translations);
  const { country, language, environment, consolidated } = useConfig();
  if (loading) {
    return <Loader />;
  }
  const coverImageAsset = createImageObject(
    'jdpCover',
    model?.branding?.coverImageUrl,
  );
  const logoImageAsset = createImageObject(
    'jdpLogo',
    model?.branding?.logoImageUrl,
  );

  const handleWriteAReviewClicked = async () => {
    trackWriteAReviewClicked();
    await new Promise((resolve) => setTimeout(resolve, 500));
  };
  const totalRating = model?.rating?.average || 0;
  const numberOfReviews = model?.rating?.count;

  return (
    <Section mode="view" loading={loading}>
      <Box paddingBottom="small">
        <Stack space="large">
          <Bleed horizontal={['xsmall', 'none', 'large']} top="large">
            <CoverImage cover={coverImageAsset} roundedCorners />
          </Bleed>
          <BrandLogo logo={logoImageAsset} />
          <Columns space="large" collapseBelow="tablet" alignY="center">
            <Column>
              <Stack space="small">
                {model?.name ? (
                  <TextLink
                    href={aboutPageLink}
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToAboutTab();
                    }}
                  >
                    <Heading level="4" component="span">{model.name}</Heading>
                  </TextLink>
                ) : null}
                {model?.rating ? (
                  <Inline space="small">
                    <Rating rating={totalRating} variant="starsOnly" />
                    <Text>
                      {totalRating.toFixed(1)} {t('total rating from')}{' '}
                      <TextLinkButton
                        onClick={navigateToReviewsTab}
                        weight="weak"
                      >
                        {numberOfReviews} {t('reviews')}
                      </TextLinkButton>
                    </Text>
                  </Inline>
                ) : null}
              </Stack>
            </Column>
            <Column width="content">
              <ButtonLink
                onClick={handleWriteAReviewClicked}
                href={buildWriteAReviewURI({
                  country,
                  language,
                  environment,
                  consolidated,
                  params: model?.name
                    ? `cn=${encodeURIComponent(model?.name)}&tracking=company-profile`
                    : undefined,
                })}
                variant="solid"
                rel="nofollow"
                size="standard"
                tone="formAccent"
                icon={<IconEdit />}
              >
                {t('Write a review')}
              </ButtonLink>
            </Column>
          </Columns>
        </Stack>
      </Box>
    </Section>
  );
}
