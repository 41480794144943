import type { PostCompanyReviewSuccess } from '@seek/ca-graphql-schema/types';
import { useConfig } from '@seek/libs-shared';
import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useService } from '../../shared/providers/serviceProvider';
import type {
  ConfirmedRoles,
  RoleTitle,
} from '../../shared/services/roleService/roleService';
import {
  trackWriteAReviewSubmitted,
  type ReviewFormContext,
} from '../../shared/tracking/writeAReview/trackingEvents';
import type { ReviewPayload } from '../type';
import { mapActionOrigin } from '../utils/mapActionOrigin';
import { mapReview } from './mapper/reviewMapper';
import { WriteAReviewPage } from './WriteAReviewPage';

export const WriteAReviewContainer = () => {
  const urlSearchParams = new URL(window.location.href).searchParams;
  const companyNameQueryParam = urlSearchParams.get('cn') || undefined;
  const tracking = urlSearchParams.get('tracking') || undefined;

  const [roleTitleSessionId, setRoleTitleSessionId] = useState<string>(uuid());
  const [locationSessionId] = useState<string>(uuid());
  // use ReviewFormContext as state is becasue we may need to move the form to modal or drawer based form, having them integrated into the AppContext may cause redo in the future
  const reviewFormActionOrigin = mapActionOrigin(tracking);
  const [reviewFormContext, _] = useState<ReviewFormContext>({
    reviewId: uuid(),
    ...(reviewFormActionOrigin ? { reviewFormActionOrigin } : {}),
  });

  const config = useConfig();

  const {
    roleService,
    companyProfileService,
    locationService,
    companyReviewService,
  } = useService();

  const getRoleTitles = async (query: string): Promise<RoleTitle[]> => {
    // If text is cleared, generate a new session id
    let currentSessionId = roleTitleSessionId;
    if (query === '') {
      currentSessionId = uuid();
      setRoleTitleSessionId(currentSessionId);
    }

    return await roleService.getRoleTitles(query, currentSessionId);
  };

  const searchCompanyProfiles = async (query: string) => {
    return await companyProfileService.getCompanyProfilesByName(query, true);
  };

  const searchLocations = async (query: string) => {
    return await locationService.getLocations(query, locationSessionId);
  };

  const getConfirmedRoles = async () => {
    return await roleService.getConfirmedRoles();
  };

  const updateRole = async (role: ConfirmedRoles) => {
    return await roleService.updateRole(role);
  };

  const handleSubmitReview = async (review: ReviewPayload) => {
    const mappedReview = mapReview(config.language, review);

    const response = await companyReviewService.submitReview(mappedReview);

    if ((response as PostCompanyReviewSuccess).reviewId) {
      trackWriteAReviewSubmitted({ config, context: reviewFormContext });
      return true;
    }

    return false;
  };

  return (
    <WriteAReviewPage
      getRoleTitles={getRoleTitles}
      searchCompanyProfiles={searchCompanyProfiles}
      searchLocations={searchLocations}
      companyNameQueryParam={companyNameQueryParam}
      reviewFormContext={reviewFormContext}
      getConfirmedRoles={getConfirmedRoles}
      updateRole={updateRole}
      submitReview={handleSubmitReview}
    />
  );
};
