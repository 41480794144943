import { createValidator } from '@seek/validators-js';
import translations from './.vocab';
import type { Salary } from '../../type';

export const { isSalaryValid } = createValidator({
  test: ({ value }: { value: Salary }) =>
    Boolean(value?.maximum) &&
    Boolean(value?.minimum) &&
    Boolean(value?.currency),
  formatErrorMessages: (messages) => () =>
    messages['Select an amount'].format(),
  translations,
  validatorName: 'isSalaryValid',
});
