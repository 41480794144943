import { type PropsWithChildren, createContext, useContext } from 'react';

import type {
  Brand,
  Country,
  Language,
  Locale,
  Product,
  SiteName,
} from '@seek/melways-sites';
import type { Environment, Zone, Experience } from '..';

export interface BaseConfig {
  name: string;
  siteName: SiteName;
  brand: Brand;
  country: Country;
  locale: Locale;
  language: Language;
  environment: Environment;
  product: Product;
  experience: Experience;
  zone: Zone;
  clientIp?: string;
  consolidated?: boolean;
}

type ConfigContextProps<T extends BaseConfig> =
  | {
      config: T;
    }
  | undefined;

type ConfigProviderProps<T extends BaseConfig> = PropsWithChildren<
  ConfigContextProps<T>
>;

const ConfigProviderContext =
  createContext<ConfigContextProps<BaseConfig>>(undefined);

export const ConfigProvider = <T extends BaseConfig>({
  children,
  config,
}: ConfigProviderProps<T>) => (
  <ConfigProviderContext.Provider value={{ config }}>
    {children}
  </ConfigProviderContext.Provider>
);

export const useConfig = <T extends BaseConfig>(): T => {
  const context = useContext(ConfigProviderContext);

  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }

  return { ...context.config } as unknown as T;
};
