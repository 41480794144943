import { buildWriteAReviewURI, Section, useConfig } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { Box, Card, Heading, Stack, Text, TextLink } from 'braid-design-system';
import translations from './.vocab';
import { SpeechBubbleIcon } from './SpeechBubbleIcon';

interface Props {
  wrapInCard?: boolean;
  companyName?: string;
  type: ReviewsEmptyStateType;
  trackWriteAReviewClicked: () => void;
}

export const ReviewsEmptyState = ({
  wrapInCard = false,
  companyName,
  type,
  trackWriteAReviewClicked,
}: Props) => {
  const { t } = useTranslations(translations);
  const { country, environment, language, consolidated } = useConfig();

  const EmptyStateBody = () => (
    <Box paddingBottom="medium">
      <Stack space="xxlarge" align="center">
        <Stack space="large" align="center">
          <SpeechBubbleIcon />
          {type === ReviewsEmptyStateType.NO_SUMMARY ? (
            <Text tone="secondary" align="center">
              {t(
                "We don't have enough reviews for {companyName} yet to show an average rating.",
                {
                  companyName: companyName || 'this company',
                },
              )}
            </Text>
          ) : (
            <Text tone="secondary" align="center">
              {t(
                "There aren't any matching reviews. Would you like to write one?",
              )}
            </Text>
          )}
        </Stack>

        <Stack space="large" align="center">
          <Heading level="3" align="center">
            {t('Have your say')}
          </Heading>
          <Stack space="medium" align="center">
            <Text tone="secondary" align="center">
              {t("Let others know what it's like to work at {companyName}.", {
                companyName: companyName || 'this company',
              })}
            </Text>
            <Text align="center">
              <TextLink
                onClick={trackWriteAReviewClicked}
                href={buildWriteAReviewURI({
                  country,
                  environment,
                  language,
                  consolidated,
                  params: companyName
                    ? `cn=${encodeURIComponent(companyName)}&tracking=company-profile`
                    : undefined,
                })}
                rel="nofollow"
              >
                {t('Write a review')}
              </TextLink>
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );

  return wrapInCard ? (
    <Section heading="Reviews">
      <Card>
        <EmptyStateBody />
      </Card>
    </Section>
  ) : (
    <EmptyStateBody />
  );
};

export enum ReviewsEmptyStateType {
  NO_LIST,
  NO_SUMMARY,
}
