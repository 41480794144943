
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4oeC4teC4nOC4peC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Liq4Li04LmI4LiH4LiX4Li14LmI4LiV4Lij4LiH4LiB4Lix4Lia4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiC4Lit4LiH4LiE4Li44LiTIiwiVHJ5IGNoZWNraW5nIGZvciBzcGVsbGluZyBlcnJvcnMuIjoi4Lil4Lit4LiH4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LiV4Lix4Lin4Liq4Liw4LiB4LiU4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiHIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4oeC4teC4nOC4peC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Liq4Li04LmI4LiH4LiX4Li14LmI4LiV4Lij4LiH4LiB4Lix4Lia4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiC4Lit4LiH4LiE4Li44LiTIiwiVHJ5IGNoZWNraW5nIGZvciBzcGVsbGluZyBlcnJvcnMuIjoi4Lil4Lit4LiH4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LiV4Lix4Lin4Liq4Liw4LiB4LiU4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiHIn0%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IlRpZGFrIGFkYSBoYXNpbCBwZW5jYXJpYW4geWFuZyBjb2NvayIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIGNvY29rLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IkNvYmEgcGVyaWtzYSBrZXNhbGFoYW4gZWphYW4uIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IlRpZGFrIGFkYSBoYXNpbCBwZW5jYXJpYW4geWFuZyBjb2NvayIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIGNvY29rLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IkNvYmEgcGVyaWtzYSBrZXNhbGFoYW4gZWphYW4uIn0%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4oeC4teC4nOC4peC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Liq4Li04LmI4LiH4LiX4Li14LmI4LiV4Lij4LiH4LiB4Lix4Lia4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiC4Lit4LiH4LiE4Li44LiTIiwiVHJ5IGNoZWNraW5nIGZvciBzcGVsbGluZyBlcnJvcnMuIjoi4Lil4Lit4LiH4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LiV4Lix4Lin4Liq4Liw4LiB4LiU4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiHIn0%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IuC5hOC4oeC5iOC4oeC4teC4nOC4peC4geC4suC4o%2BC4hOC5ieC4meC4q%2BC4suC4l%2BC4teC5iOC4leC4o%2BC4h%2BC4geC4seC4mSIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoi4LmA4Lij4Liy4LmE4Lih4LmI4Lie4Lia4Liq4Li04LmI4LiH4LiX4Li14LmI4LiV4Lij4LiH4LiB4Lix4Lia4LiB4Liy4Lij4LiE4LmJ4LiZ4Lir4Liy4LiC4Lit4LiH4LiE4Li44LiTIiwiVHJ5IGNoZWNraW5nIGZvciBzcGVsbGluZyBlcnJvcnMuIjoi4Lil4Lit4LiH4LiV4Lij4Lin4LiI4Liq4Lit4Lia4LiV4Lix4Lin4Liq4Liw4LiB4LiU4Lit4Li14LiB4LiE4Lij4Lix4LmJ4LiHIn0%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IlRpZGFrIGFkYSBoYXNpbCBwZW5jYXJpYW4geWFuZyBjb2NvayIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIGNvY29rLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IkNvYmEgcGVyaWtzYSBrZXNhbGFoYW4gZWphYW4uIn0%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IlRpZGFrIGFkYSBoYXNpbCBwZW5jYXJpYW4geWFuZyBjb2NvayIsIldlIGNvdWxkbuKAmXQgZmluZCBhbnl0aGluZyB0aGF0IG1hdGNoZWQgeW91ciBzZWFyY2guIjoiVGlkYWsgZGl0ZW11a2FuIGhhc2lsIHBlbmNhcmlhbiB5YW5nIGNvY29rLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IkNvYmEgcGVyaWtzYSBrZXNhbGFoYW4gZWphYW4uIn0%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6Ik5vIG1hdGNoaW5nIHNlYXJjaCByZXN1bHRzIiwiV2UgY291bGRu4oCZdCBmaW5kIGFueXRoaW5nIHRoYXQgbWF0Y2hlZCB5b3VyIHNlYXJjaC4iOiJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6IlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiJ9!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IlvDkcO2w7bDtiBtzILEg8SDxIPhua%2FDp%2BG4qcOsw6zDrOC4geC4teC5icSjIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qSDFmeG6veG6veG6vcWhx5rHmseaxprhua%2FFoV0iLCJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IlvFtOG6veG6veG6vSDDp8O2w7bDtseax5rHmsaaxozguIHguLXguYnigJnhua8gxpLDrMOsw6zguIHguLXguYnGjCDEg8SDxIPguIHguLXguYnDvcO9w73hua%2FhuKnDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnEg8SDxIPhua8gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3GjCDDvcO9w73DtsO2w7bHmseax5rFmSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6Ilvhua7FmcO9w73DvSDDp%2BG4qeG6veG6veG6vcOnxLfDrMOsw6zguIHguLXguYnEoyDGksO2w7bDtsWZIMWhxqXhur3hur3hur3Gmsaaw6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq9xZnFmcO2w7bDtsWZxaEuXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../../../../node_modules/.pnpm/virtual-resource-loader@2.0.0/node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJObyBtYXRjaGluZyBzZWFyY2ggcmVzdWx0cyI6IlvDkcO2w7bDtiBtzILEg8SDxIPhua%2FDp%2BG4qcOsw6zDrOC4geC4teC5icSjIMWh4bq94bq94bq9xIPEg8SDxZnDp%2BG4qSDFmeG6veG6veG6vcWhx5rHmseaxprhua%2FFoV0iLCJXZSBjb3VsZG7igJl0IGZpbmQgYW55dGhpbmcgdGhhdCBtYXRjaGVkIHlvdXIgc2VhcmNoLiI6IlvFtOG6veG6veG6vSDDp8O2w7bDtseax5rHmsaaxozguIHguLXguYnigJnhua8gxpLDrMOsw6zguIHguLXguYnGjCDEg8SDxIPguIHguLXguYnDvcO9w73hua%2FhuKnDrMOsw6zguIHguLXguYnEoyDhua%2FhuKnEg8SDxIPhua8gbcyCxIPEg8SD4bmvw6fhuKnhur3hur3hur3GjCDDvcO9w73DtsO2w7bHmseax5rFmSDFoeG6veG6veG6vcSDxIPEg8WZw6fhuKkuXSIsIlRyeSBjaGVja2luZyBmb3Igc3BlbGxpbmcgZXJyb3JzLiI6Ilvhua7FmcO9w73DvSDDp%2BG4qeG6veG6veG6vcOnxLfDrMOsw6zguIHguLXguYnEoyDGksO2w7bDtsWZIMWhxqXhur3hur3hur3Gmsaaw6zDrMOs4LiB4Li14LmJxKMg4bq94bq94bq9xZnFmcO2w7bDtsWZxaEuXSJ9!"
        )
      )
      });
  
      export { translations as default };
    