import type { AppConfig } from '../../config';
import type { GetJobsListResponse } from './types';

import { creatRestClient, type Jobs, type Logger } from '@seek/libs-shared';
import type { Locale } from '@seek/melways-sites';
import { buildJobSearchUrlForSearch, mapJobsModel } from './mapper';

const DEFAULT_PAGE_SIZE = 10;

interface GetJobsListPayload {
  organisationId: string;
  page: number;
  country: string;
  locale: Locale;
}

export type JobService = ReturnType<typeof createJobService>;

export const createJobService = ({
  config,
  logger,
}: {
  config: AppConfig;
  logger: Logger;
}) => {
  const { searchApiBaseUrl } = config;
  const { get } = creatRestClient(searchApiBaseUrl, logger, {
    'User-Agent': 'bx-frontends',
  });

  const getJobsList = async ({
    organisationId,
    page,
    country,
    locale,
  }: GetJobsListPayload): Promise<Jobs> => {
    const jobsUrl = buildJobSearchUrlForSearch({
      organisationId,
      page,
      pageSize: DEFAULT_PAGE_SIZE,
      country,
      source: 'COMPANY',
      locale,
    });
    const res = await get<GetJobsListResponse>(jobsUrl);

    return mapJobsModel(res?.data);
  };

  return {
    getJobsList,
  };
};
