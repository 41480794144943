import { useTranslations } from '@vocab/react';
import translations from './.vocab';

import type { QuestionProps, SalaryType } from '../../type';

import {
  SegmentedButtonSelector,
  type SegmentedButtonOptions,
} from '@seek/branding-assets';
import { useConfig } from '@seek/libs-shared';
import { useEffect } from 'react';
import { mapCountryCodeToDefaultFrequency } from '@seek/salary-data';
import type { Country } from '@seek/melways-sites';

export const SalaryTypeQuestion = ({
  value,
  message,
  onChange,
}: QuestionProps<SalaryType>) => {
  const { t } = useTranslations(translations);
  const { zone, country } = useConfig();

  const defaultSalaryType: SalaryType = getDefaultSalaryType(country);

  let options: SegmentedButtonOptions<SalaryType> = [
    { value: 'Monthly', text: t('Monthly') },
    { value: 'HourlyRate', text: t('Hourly') },
  ];

  if (['anz-1', 'anz-2'].includes(zone)) {
    options = [
      { value: 'AnnualPackage', text: t('Annual') },
      { value: 'HourlyRate', text: t('Hourly') },
    ];
  }

  // Set default value
  useEffect(() => {
    if (value === undefined && zone) {
      onChange(defaultSalaryType);
    }
  }, [zone, value]);

  return (
    <SegmentedButtonSelector<SalaryType>
      label={t('Your pay frequency')}
      value={value}
      options={options}
      onChange={onChange}
      message={message}
    />
  );
};

const getDefaultSalaryType = (country: Country): SalaryType => {
  switch (mapCountryCodeToDefaultFrequency(country)) {
    case 'MONTHLY':
      return 'Monthly';
    case 'HOURLY':
      return 'HourlyRate';
    case 'ANNUAL':
    default:
      return 'AnnualPackage';
  }
};
