import type { Language } from '@seek/melways-sites';
import { type Validator, runValidation } from '@seek/validators-js';
import { useConfig } from '..';
import { useState } from 'react';

export type Rules<Fields extends Record<string, unknown>> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [Key in keyof Fields]: {
    validators: Validator<
      unknown,
      unknown,
      Record<string, unknown>,
      Language
    >[];
    validatorProps?: Record<string, unknown>;
  };
};

type Fields = Record<string, unknown> | NonNullable<unknown>;

export interface ValidationError {
  message: string;
  fieldName: string;
}

export const useValidation = <
  TFields = Record<string, unknown>,
  TExtra = keyof TFields,
>(
  fields: Fields,
  rules: Rules<Fields>,
) => {
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const config = useConfig();

  const { language } = config;

  const getFirstErrorFieldName = (): string | undefined =>
    errors?.[0]?.fieldName;

  const getFirstError = (fieldName: keyof TFields | TExtra) => {
    const fieldError = errors?.filter(
      (val: { fieldName: string }) => val.fieldName === fieldName,
    );
    return fieldError?.[0];
  };

  const getMessage = (fieldName: keyof TFields | TExtra) => {
    const fieldError = getFirstError(fieldName);
    return fieldError?.message ?? '';
  };

  const getTone = (fieldName: keyof TFields | TExtra) => {
    const fieldError = getFirstError(fieldName);
    return fieldError ? 'critical' : 'neutral';
  };

  const filterFieldsInRules = (fieldsIn: Fields) => {
    const keys = fieldsIn ? Object.keys(fieldsIn) : [];
    const newRules = { ...rules };
    Object.keys(rules).forEach((key) => {
      if (!keys.includes(key)) {
        delete newRules[key];
      }
    });

    return newRules;
  };

  const combineErrors = (
    fieldsIn: Fields,
    validationErrors: ValidationError[],
  ): ValidationError[] => {
    const keys = Object.keys(fieldsIn);
    const newErrors = errors.filter((err) => !keys.includes(err.fieldName));

    return [...newErrors, ...validationErrors];
  };

  const validateWithErrors = (fieldsIn?: Fields) => {
    // This block is when fields are passed, we only validate those fields passed in against the rules belong to those fields
    if (fieldsIn) {
      const { valid, errors: validationErrors } = runValidation<
        Fields,
        Language
      >(
        {
          fields: fieldsIn,
          rules: filterFieldsInRules(fieldsIn),
        },
        language,
      );

      setErrors(combineErrors(fieldsIn, validationErrors));
      return {
        isValid: valid,
        errors: validationErrors,
      };
    }

    // This block is when no fields are passed, we validate all fields aganist all rules
    const { valid, errors: validationErrors } = runValidation(
      {
        fields,
        rules,
      },
      language,
    );

    setErrors(validationErrors);
    return {
      isValid: valid,
      errors: validationErrors,
    };
  };

  const validate = (fieldsIn?: Fields) => validateWithErrors(fieldsIn).isValid;

  const handleChangeWithValidation =
    ({
      validatorProperty,
      setter,
      parentCallBack,
    }: {
      validatorProperty: string;
      setter?: React.Dispatch<React.SetStateAction<string>>;
      parentCallBack?: (value: string) => void;
    }) =>
    (
      event:
        | React.FormEvent<HTMLInputElement>
        | React.FormEvent<HTMLSelectElement>
        | React.FormEvent<HTMLTextAreaElement>,
    ) => {
      const value = event.currentTarget.value;
      if (setter) {
        setter(value);
      }
      if (parentCallBack) {
        parentCallBack(value);
      }
      validate({ [validatorProperty]: value });
    };

  const handleValueChangeWithValidation =
    ({
      validatorProperty,
      previousValue,
      setter,
      parentCallBack,
    }: {
      validatorProperty: keyof TFields;
      previousValue: TFields;
      setter?: React.Dispatch<React.SetStateAction<TFields>>;
      parentCallBack?: (value: TFields) => void;
    }) =>
    (value: TFields[keyof TFields]) => {
      if (setter) {
        setter({
          ...previousValue,
          [validatorProperty]: value,
        });
      }
      if (parentCallBack) {
        parentCallBack({
          ...previousValue,
          [validatorProperty]: value,
        });
      }
      validate({
        [validatorProperty]: value,
      });
    };

  const handleOnClearWithValidation =
    ({
      validatorProperty,
      clearedValue = '',
      setter,
      parentCallBack,
    }: {
      validatorProperty: string;
      clearedValue?: string;
      setter?: React.Dispatch<React.SetStateAction<string>>;
      parentCallBack?: (value: string) => void;
    }) =>
    () => {
      if (setter) {
        setter(clearedValue);
      }
      if (parentCallBack) {
        parentCallBack(clearedValue);
      }
      validate({ [validatorProperty]: clearedValue });
    };

  const getErrors = () => errors;

  return {
    validate,
    validateWithErrors,
    getTone,
    getMessage,
    handleOnClearWithValidation,
    handleChangeWithValidation,
    handleValueChangeWithValidation,
    getFirstErrorFieldName,
    getErrors,
  };
};
