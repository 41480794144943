import {
  type BaseConfig,
  sendLinkEvent,
  sendViewEvent,
} from '@seek/libs-shared';

export type ReviewFormActionOrigin =
  | 'Company Profile'
  | 'Candidate Profile'
  | 'Companies'
  | 'Career Advice'
  | null;

export interface ReviewFormContext {
  reviewFormActionOrigin?: ReviewFormActionOrigin;
  reviewId: string;
}

interface CommonInputType {
  config: BaseConfig;
  context: ReviewFormContext;
}

interface ValidationErrorCommonInputType {
  errorMessage: string[];
  errorText: string[];
}

interface ContinueValidationCommonInputType {
  validation: 'mandatory_questions_not_answered' | 'success';
}

const sendViewEventWithCommonProps = ({
  eventName,
  config,
  context,
  trackingProps,
}: {
  eventName: string;
  config: BaseConfig;
  context: ReviewFormContext;
  trackingProps: Record<string, unknown>;
}) => {
  sendViewEvent(eventName, config, {
    ...trackingProps,
    ...context,
  });
};

const sendLinkEventWithCommonProps = ({
  eventName,
  config,
  context,
  trackingProps,
}: {
  eventName: string;
  config: BaseConfig;
  context: ReviewFormContext;
  trackingProps: Record<string, unknown>;
}) => {
  sendLinkEvent(eventName, config, {
    ...trackingProps,
    ...context,
  });
};

// Company Intercept Page

const companyInterceptPageCommonTrackingProps = () => ({
  currentPage: 'cr_company_intercept',
});

export const trackDisplayCompanyInterceptPage = ({
  config,
  context,
}: CommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_company_intercept_displayed',
    config,
    context,
    trackingProps: {
      ...companyInterceptPageCommonTrackingProps(),
    },
  });
};

export const trackClickCompanyInterceptSelection = ({
  config,
  context,
  selectedCompanyId,
  cardPosition,
}: CommonInputType & {
  selectedCompanyId: string;
  cardPosition: number;
}) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_selection_pressed',
    config,
    context,
    trackingProps: {
      ...companyInterceptPageCommonTrackingProps(),
      companyId: selectedCompanyId,
      cardPosition,
    },
  });
};

export const trackClickCompanyInterceptReviewDifferentCompany = ({
  config,
}: CommonInputType) => {
  sendLinkEvent(
    'cr_company_intercept_review_different_company_pressed',
    config,
    {
      ...companyInterceptPageCommonTrackingProps(),
    },
  );
};

// Overall Page

interface OveralPageSubSectionInputType {
  currentPageSubsection: 'company_name' | 'overall_experience';
}

const overallPageCommonTrackingProps = () => ({
  currentPage: 'cr_write_a_review_overall_rating',
});

export const trackWriteAReviewStarted = ({
  config,
  context,
}: CommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_write_a_review_started',
    config,
    context,
    trackingProps: {
      ...overallPageCommonTrackingProps(),
    },
  });
};

export const trackDisplayOverallPage = ({
  config,
  context,
}: CommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_write_a_review_overall_rating_displayed',
    config,
    context,
    trackingProps: {
      ...overallPageCommonTrackingProps(),
    },
  });
};

export const trackClickOverallPageContinue = ({
  config,
  context,
}: CommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_write_a_review_overall_rating_continue_pressed',
    config,
    context,
    trackingProps: {
      ...overallPageCommonTrackingProps(),
    },
  });
};

export const trackClickOverallPageSubSection = ({
  config,
  context,
  currentPageSubsection,
}: CommonInputType & OveralPageSubSectionInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_selection_pressed',
    config,
    context,
    trackingProps: {
      ...overallPageCommonTrackingProps(),
      currentPageSubsection,
    },
  });
};

export const trackValidationErrorOverallPage = ({
  config,
  context,
  errorMessage,
  errorText,
}: ValidationErrorCommonInputType & CommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_validation_error',
    config,
    context,
    trackingProps: {
      ...overallPageCommonTrackingProps(),
      errorMessage,
      errorText,
    },
  });
};

// Your Role Page

interface YourRolePageCommonInputType extends CommonInputType {
  prefilled: boolean;
}

const yourRolePageCommonTrackingProps = ({
  prefilled = false,
}: {
  prefilled?: boolean;
}) => ({
  currentPage: 'cr_write_a_review_your_role',
  prefilled,
});

export const trackDisplayYourRolePage = ({
  config,
  context,
  prefilled,
}: YourRolePageCommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_write_a_review_your_role_displayed',
    config,
    context,
    trackingProps: {
      ...yourRolePageCommonTrackingProps({ prefilled }),
    },
  });
};

export const trackClickYourRolePageContinue = ({
  config,
  context,
  prefilled,
}: YourRolePageCommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_write_a_review_your_role_continue_pressed',
    config,
    context,
    trackingProps: {
      ...yourRolePageCommonTrackingProps({ prefilled }),
    },
  });
};

interface YourRoleSubSectionInputType {
  currentPageSubsection:
    | 'job_title'
    | 'location_selected'
    | 'work_type'
    | 'start_date'
    | 'end_date'
    | 'i_still_work_here';
}

export const trackClickYourRoleSubSection = ({
  config,
  context,
  currentPageSubsection,
  prefilled,
}: YourRolePageCommonInputType & YourRoleSubSectionInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_selection_pressed',
    config,
    context,
    trackingProps: {
      ...yourRolePageCommonTrackingProps({ prefilled }),
      currentPageSubsection,
    },
  });
};

export const trackValidationErrorYourRolePage = ({
  config,
  context,
  errorMessage,
  errorText,
  prefilled,
}: ValidationErrorCommonInputType & YourRolePageCommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_validation_error',
    config,
    context,
    trackingProps: {
      ...yourRolePageCommonTrackingProps({ prefilled }),
      errorMessage,
      errorText,
    },
  });
};

// Your Experience Page

const yourExperiencePageCommonTrackingProps = () => ({
  currentPage: 'cr_write_a_review_your_experience',
});

export const trackDisplayYourExperiencePage = ({
  config,
  context,
}: CommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_write_a_review_your_experience_displayed',
    config,
    context,
    trackingProps: {
      ...yourExperiencePageCommonTrackingProps(),
    },
  });
};

export const trackClickYourExperiencePageContinue = ({
  config,
  context,
  validation,
}: CommonInputType & ContinueValidationCommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_write_a_review_your_experience_continue_pressed',
    config,
    context,
    trackingProps: {
      ...yourExperiencePageCommonTrackingProps(),
      validation,
    },
  });
};

export const trackValidationErrorYourExperiencePage = ({
  config,
  context,
  errorMessage,
  errorText,
}: ValidationErrorCommonInputType & CommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_validation_error',
    config,
    context,
    trackingProps: {
      ...yourExperiencePageCommonTrackingProps(),
      errorMessage,
      errorText,
    },
  });
};

interface YourExperienceSubSectionInputType {
  currentPageSubsection: 'recommend_to_a_friend';
}

export const trackClickYourExperienceSubSection = ({
  config,
  context,
  currentPageSubsection,
}: CommonInputType & YourExperienceSubSectionInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_selection_pressed',
    config,
    context,
    trackingProps: {
      ...yourExperiencePageCommonTrackingProps(),
      currentPageSubsection,
    },
  });
};

// Your Salary Page

const yourSalaryPageCommonTrackingProps = () => ({
  currentPage: 'cr_write_a_review_your_salary',
});

export const trackDisplayYourSalaryPage = ({
  config,
  context,
}: CommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_write_a_review_your_salary_displayed',
    config,
    context,
    trackingProps: {
      ...yourSalaryPageCommonTrackingProps(),
    },
  });
};

export const trackClickYourSalaryPageContinue = ({
  config,
  context,
  validation,
}: CommonInputType & ContinueValidationCommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_write_a_review_your_salary_continue_pressed',
    config,
    context,
    trackingProps: {
      ...yourSalaryPageCommonTrackingProps(),
      validation,
    },
  });
};

export const trackValidationErrorYourSalaryPage = ({
  config,
  context,
  errorMessage,
  errorText,
}: ValidationErrorCommonInputType & CommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_validation_error',
    config,
    context,
    trackingProps: {
      ...yourSalaryPageCommonTrackingProps(),
      errorMessage,
      errorText,
    },
  });
};

interface YourSalarySubSectionInputType {
  currentPageSubsection:
    | 'salary_rating'
    | 'pay_frequency'
    | 'salary_amount'
    | 'bonus';
}

export const trackClickYourSalarySubSection = ({
  config,
  context,
  currentPageSubsection,
}: CommonInputType & YourSalarySubSectionInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_selection_pressed',
    config,
    context,
    trackingProps: {
      ...yourSalaryPageCommonTrackingProps(),
      currentPageSubsection,
    },
  });
};

// Your Feedback Page

const yourFeedbackPageCommonTrackingProps = () => ({
  currentPage: 'cr_write_a_review_your_feedback',
});

export const trackDisplayYourFeedbackPage = ({
  config,
  context,
}: CommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_write_a_review_your_feedback_displayed',
    config,
    context,
    trackingProps: {
      ...yourFeedbackPageCommonTrackingProps(),
    },
  });
};

interface YourFeedbackRatingPressInputType {
  currentPageSubsection:
    | 'career_development'
    | 'work_life_balance'
    | 'working_environment'
    | 'management'
    | 'perks_and_benefits'
    | 'diversity_and_equal_opportunity';
}

export const trackClickYourFeedbackRating = ({
  config,
  context,
  currentPageSubsection,
}: CommonInputType & YourFeedbackRatingPressInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_selection_pressed',
    config,
    context,
    trackingProps: {
      ...yourFeedbackPageCommonTrackingProps(),
      currentPageSubsection,
    },
  });
};

export const trackClickYourFeedbackPageContinue = ({
  config,
  context,
  validation,
}: CommonInputType & ContinueValidationCommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_write_a_review_your_feedback_continue_pressed',
    config,
    context,
    trackingProps: {
      ...yourFeedbackPageCommonTrackingProps(),
      validation,
    },
  });
};

export const trackValidationErrorYourFeedbackPage = ({
  config,
  context,
  errorMessage,
  errorText,
}: ValidationErrorCommonInputType & CommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_validation_error',
    config,
    context,
    trackingProps: {
      ...yourFeedbackPageCommonTrackingProps(),
      errorMessage,
      errorText,
    },
  });
};

// Submission Page

const submissionPageCommonTrackingProps = () => ({
  currentPage: 'cr_write_a_review_completed',
});

export const trackWriteAReviewSubmitted = ({
  config,
  context,
}: CommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_write_a_review_submitted',
    config,
    context,
    trackingProps: {
      ...submissionPageCommonTrackingProps(),
    },
  });
};

export const trackDisplaySubmissionPage = ({
  config,
  context,
}: CommonInputType) => {
  sendViewEventWithCommonProps({
    eventName: 'cr_write_a_review_complete_displayed',
    config,
    context,
    trackingProps: {
      ...submissionPageCommonTrackingProps(),
    },
  });
};

export const trackClickSubmissionPageGoToCompanyProfile = ({
  config,
  context,
}: CommonInputType) => {
  sendLinkEventWithCommonProps({
    eventName: 'cr_write_a_review_complete_company_profile_pressed',
    config,
    context,
    trackingProps: {
      ...submissionPageCommonTrackingProps(),
    },
  });
};
