import { useTranslations } from '@vocab/react';
import {
  Box,
  FieldLabel,
  FieldMessage,
  Inline,
  Stack,
} from 'braid-design-system';
import { useState } from 'react';
import translations from './.vocab';
import { Star } from './Star';

const RATING_VALUES = ['1', '2', '3', '4', '5'] as const;

export type Rating = (typeof RATING_VALUES)[number];

type AreStarsSelected = {
  [key in Rating]: boolean;
};

export const StarSelector = ({
  value,
  id,
  title,
  message,
  onChange,
  isRequired = false,
}: {
  id: string;
  title: string;
  value?: Rating;
  message?: string;
  isRequired?: boolean;
  onChange: (rating: Rating) => void;
}) => {
  const { t } = useTranslations(translations);
  const getStarsStatusByRating = (
    rating: Rating | undefined,
  ): AreStarsSelected => {
    const status = {
      '1': false,
      '2': false,
      '3': false,
      '4': false,
      '5': false,
    };

    RATING_VALUES.map((starRating) => {
      status[starRating] = starRating <= (rating ?? '0');
    });

    return status;
  };

  const [areStarsSelected, setAreStarsSelected] = useState<AreStarsSelected>(
    getStarsStatusByRating(value),
  );

  const handleOnClick = (rating: Rating) => () => {
    updateStars(rating);
    if (rating !== value) {
      onChange(rating);
    }
  };

  const onMouseEnter = (rating: Rating) => () => {
    updateStars(rating);
  };

  const onMouseLeave = () => {
    updateStars(value);
  };

  const updateStars = (rating: Rating | undefined) => {
    const newAreStarsSelected = getStarsStatusByRating(rating);
    setAreStarsSelected(newAreStarsSelected);
  };

  return (
    <Box id={id} tabIndex={0} data={{ testid: 'star-selector' }}>
      <Stack space="small">
        <FieldLabel
          htmlFor={id}
          label={title}
          secondaryLabel={!isRequired ? t('Optional') : undefined}
        />
        <Inline space="xsmall">
          <Star
            label={t('1 star')}
            isSelected={areStarsSelected['1']}
            onClick={handleOnClick('1')}
            onMouseEnter={onMouseEnter('1')}
            onMouseLeave={onMouseLeave}
          />
          <Star
            label={t('2 stars')}
            isSelected={areStarsSelected['2']}
            onClick={handleOnClick('2')}
            onMouseEnter={onMouseEnter('2')}
            onMouseLeave={onMouseLeave}
          />
          <Star
            label={t('3 stars')}
            isSelected={areStarsSelected['3']}
            onClick={handleOnClick('3')}
            onMouseEnter={onMouseEnter('3')}
            onMouseLeave={onMouseLeave}
          />
          <Star
            label={t('4 stars')}
            isSelected={areStarsSelected['4']}
            onClick={handleOnClick('4')}
            onMouseEnter={onMouseEnter('4')}
            onMouseLeave={onMouseLeave}
          />
          <Star
            label={t('5 stars')}
            isSelected={areStarsSelected['5']}
            onClick={handleOnClick('5')}
            onMouseEnter={onMouseEnter('5')}
            onMouseLeave={onMouseLeave}
          />
        </Inline>

        {message ? (
          <FieldMessage id={`${id}-error`} tone="critical" message={message} />
        ) : null}
      </Stack>
    </Box>
  );
};
