import {Box, Heading, HiddenVisually} from 'braid-design-system';
import { useEffect } from 'react';

import {
  type Jobs,
  JobSearchSection,
  JobsSection,
  useAppContext,
  useConfig,
} from '@seek/libs-shared';
import type { JobsModel } from './jobsModel';

import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import { useAuth } from '../../../shared/providers/authProvider';
import { trackViewEvents } from '../../../shared/tracking/candidateApp/trackViewEvents';
import { NoJobsFound } from '../../components/noJobsFound/NoJobsFound';
import {useTranslations} from "@vocab/react";
import translations from "./.vocab";

interface JobsPageProps {
  model: JobsModel;
  getPaginatedJobs: (companyId: string, page?: number) => Promise<Jobs>;
  trackJobsPaginationClicked: ({
    searchTotalCount,
    pageNumber,
  }: {
    searchTotalCount?: number | undefined;
    pageNumber: number;
  }) => void;
}

export function JobsPage({
  model,
  getPaginatedJobs,
  trackJobsPaginationClicked,
}: JobsPageProps) {
  const config = useConfig();
  const { companyId, companyName, ...context } = useAppContext();
  const { authenticationStatus } = useAuth();
  const { t } = useTranslations(translations);

  useEffect(() => {
    if (
      companyId &&
      companyName &&
      authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING
    )
      trackViewEvents.jobsPage({
        config,
        context: { ...context, companyId, companyName },
        searchTotalCount: model?.totalJobs || 0,
      });
  }, [companyId, companyName, authenticationStatus]);

  if (model.totalJobs === 0) {
    return (
      <Box paddingY="xxlarge" paddingX={['medium', 'none']}>
        <NoJobsFound companyName={model.companyName} />
      </Box>
    );
  }

  return (
    <>
      <HiddenVisually>
        <Heading level="1">
          {t('Company name Jobs', {
            companyName,
          })}
        </Heading>
      </HiddenVisually>
      <JobSearchSection mode="view" model={model} />
      <JobsSection
        mode="view"
        model={model}
        getPaginatedJobs={getPaginatedJobs}
        trackJobsPaginationClicked={trackJobsPaginationClicked}
      />
    </>
  );
}
