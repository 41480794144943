import { useTranslations } from '@vocab/react';
import { Card, Column, Columns } from 'braid-design-system';

import translations from '../reviewsSummarySection/.vocab';

import { PercentageWheel } from '../percentageWheel/PercentageWheel';
import { RatingBreakdown } from '../ratingBreakdown/RatingBreakdown';
import { RatingSummary } from '../ratingSummary/RatingSummary';
import type { ReviewsSummarySectionModel } from '../reviewsSummarySection/ReviewsSummarySection';

type Props = {
  model: Pick<
    ReviewsSummarySectionModel,
    | 'employeeRecommendationRating'
    | 'overallRating'
    | 'ratingBreakdown'
    | 'salaryRating'
  >;
};

export const ReviewsSummaryCard = ({ model }: Props) => {
  const { t } = useTranslations(translations);
  const {
    overallRating,
    ratingBreakdown,
    salaryRating,
    employeeRecommendationRating,
  } = model;

  return (
    <Card>
      <Columns
        space={['xlarge', 'large']}
        alignY="center"
        collapseBelow="tablet"
      >
        <Column width="1/4">
          <RatingSummary model={overallRating} />
        </Column>
        <Column width="1/4">
          <RatingBreakdown model={ratingBreakdown} />
        </Column>
        <Column width="1/4">
          <PercentageWheel
            text={t('rate salary as high or average')}
            model={{ percentage: salaryRating }}
            mode="view"
            isMoney
          />
        </Column>
        <Column width="1/4">
          <PercentageWheel
            text={t('employees recommend this employer to friends')}
            model={{ percentage: employeeRecommendationRating }}
            mode="view"
          />
        </Column>
      </Columns>
    </Card>
  );
};
