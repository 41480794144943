import { useTranslations } from '@vocab/react';
import translations from './.vocab';
import { getHostName } from '@seek/melways-sites';
import { useAppContext, useConfig } from '@seek/libs-shared';
import { useEffect } from 'react';
import { Stack } from 'braid-design-system';

import { MainLayout } from '../../layouts/mainLayout/MainLayout';
import { HomePageBanner } from '../../components/homePageBanner/HomePageBanner';
import { ExploreCompanies } from '../../components/exploreCompanies/ExploreCompanies';
import { ValuePropMarketing } from '../../components/valuePropMarketing/ValuePropMarketing';
import { MetaTag } from '../../components/MetaTag/MetaTag';
import { getMetaData } from '../../components/MetaTag/meta';
import type { AppConfig } from '../../../shared/config';
import { trackViewEvents } from '../../../shared/tracking/candidateApp/trackViewEvents';
import { useAuth } from '../../../shared/providers/authProvider';
import { AUTHENTICATION_TYPES } from '@seek/apac-candidate-header-footer/types';
import type { HomePageModel } from './homePageModel';
import { CompaniesList } from '../../components/companiesList/CompaniesList';
import { trackLinkEvents } from '../../../shared/tracking/candidateApp/trackLinkEvents';

export function HomePage({ model }: { model: HomePageModel }) {
  const config = useConfig<AppConfig>();
  const { userId } = useAuth();
  const { companyId, companyName, ...context } = useAppContext();
  const { authenticationStatus } = useAuth();
  const { t } = useTranslations(translations);

  const trackTopCompanyProfileLinkClicked = ({
    linkedCompanyId,
  }: {
    linkedCompanyId: string;
  }) => {
    trackLinkEvents.trackTopCompanyProfileLinkClicked({
      config,
      context,
      linkedCompanyId,
    });
  };

  useEffect(() => {
    if (authenticationStatus !== AUTHENTICATION_TYPES.AUTH_PENDING)
      trackViewEvents.homePage({
        config,
        context: { ...context },
      });
  }, [authenticationStatus]);

  const {
    siteName: site,
    environment,
    absoluteUrl,
    featureFlags,
    consolidated,
  } = config;

  useEffect(() => {
    scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <MainLayout showMarginTopAndBottom={false}>
      <MetaTag
        {...getMetaData(config, {
          brandUrl: getHostName({
            site,
            staging: environment === 'staging' || environment === 'development',
            ...(consolidated ? { alternative: 'subDomainRegion' } : {}),
          }),
          absoluteUrl,
        })}
      />
      <Stack space={['large', 'xxlarge']}>
        <HomePageBanner />
        <Stack space="xxlarge">
          {featureFlags.shouldDisplayCompanyCarousel ? (
            <ExploreCompanies userId={userId} />
          ) : null}
          <ValuePropMarketing />
        </Stack>
        <CompaniesList
          title={t('Top companies')}
          allCompanies={model.topCompanies}
          trackCompanyProfileLinkClicked={trackTopCompanyProfileLinkClicked}
        />
      </Stack>
    </MainLayout>
  );
}
