import {
  brandName,
  ORPHAN_REVIEW_COMPANY_ID,
  removeUndefinedProps,
  useConfig,
  useValidation,
} from '@seek/libs-shared';
import translations from './.vocab';

import { Box, Loader } from 'braid-design-system';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import type { SearchCompanyProfileResult } from '../../shared/services/companyProfileService/types';
import type { LocationSuggestion } from '../../shared/services/locationService/locationService';
import type {
  ConfirmedRoles,
  RoleTitle,
} from '../../shared/services/roleService/roleService';
import {
  trackClickCompanyInterceptReviewDifferentCompany,
  trackClickCompanyInterceptSelection,
  trackWriteAReviewStarted,
  type ReviewFormContext,
} from '../../shared/tracking/writeAReview/trackingEvents';
import { Progress, type ReviewPayload } from '../type';
import { validationRules } from '../validation/validationRules';
import { CompaniesListPage } from './CompaniesListPage';
import { OverallRatingPage } from './OverallRatingPage';
import { SubmitSuccessPage } from './SubmitSuccessPage';
import { YourExperiencePage } from './YourExperiencePage';
import { YourFeedbackPage } from './YourFeedbackPage';
import { YourRolePage } from './YourRolePage';
import { YourSalaryPage } from './YourSalaryPage';

import { useTranslations } from '@vocab/react';
import { MetaTag } from '../../companyProfiles/components/MetaTag/MetaTag';
type Props = {
  getRoleTitles: (query: string) => Promise<RoleTitle[]>;
  searchCompanyProfiles: (query: string) => Promise<SearchCompanyProfileResult>;
  getConfirmedRoles: () => Promise<ConfirmedRoles[]>;
  searchLocations: (query: string) => Promise<LocationSuggestion[]>;
  companyNameQueryParam?: string;
  reviewFormContext: ReviewFormContext;
  updateRole: (role: ConfirmedRoles) => Promise<Boolean>;
  submitReview: (review: ReviewPayload) => Promise<Boolean>;
};

export const WriteAReviewPage = ({
  getRoleTitles,
  searchCompanyProfiles,
  searchLocations,
  companyNameQueryParam,
  reviewFormContext,
  getConfirmedRoles,
  updateRole,
  submitReview,
}: Props) => {
  const [review, setReview] = useState<ReviewPayload>({});
  const [page, setPage] = useState<Progress>(Progress.OverallRating);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchCompanyProfileResult, setSearchCompanyProfileResult] =
    useState<SearchCompanyProfileResult>([]);
  const [selectedRole, setSelectedRole] = useState<ConfirmedRoles>();
  const [showCompaniesListPage, setShowCompaniesListPage] =
    useState<boolean>(false);
  const [companySelectionIsValidated, setCompanySelectionIsValidated] =
    useState<boolean>(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const config = useConfig();
  const { t } = useTranslations(translations);

  const { validate, getErrors } = useValidation<ReviewPayload>(
    review,
    removeUndefinedProps({
      ...validationRules(config.language),
      ...(review.isStillWorkHere
        ? {
            workHistoryTo: undefined,
            workHistory: undefined,
          }
        : {}),
    }),
  );

  const handleOnSubmit = async () => {
    setIsSubmitting(true);
    const isValid = validate();
    if (!isValid) {
      setIsSubmitting(false);
      return;
    }
    try {
      const isSuccess = await submitReview(review);
      if (isSuccess) {
        handleOnSetPage(Progress.SubmitSuccess);
      } else {
        setIsSubmitFailed(true);
      }
    } catch {
      setIsSubmitFailed(true);
    }
    setIsSubmitting(false);
  };

  const handleOnSetPage = (page: Progress) => {
    setPage(page);
    setIsSubmitFailed(false);
    scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onSearchCompanyProfiles = async (query: string) => {
    return await searchCompanyProfiles(query);
  };

  const onSearchLocations = async (query: string) => {
    return await searchLocations(query);
  };

  const onUpdateRole = async (role: ConfirmedRoles) => {
    if (
      role.title.text !== selectedRole?.title.text ||
      role.company?.text !== selectedRole?.company?.text ||
      role.from !== selectedRole?.from ||
      role.to !== selectedRole?.to ||
      role.id !== selectedRole?.id
    ) {
      const updatedRole = {
        ...selectedRole,
        ...role,
      };

      await updateRole(updatedRole);
      setSelectedRole(updatedRole);
    }
  };

  const handleOnCompanyNameClick = (companyIndex: number) => {
    setReview({
      companyName: searchCompanyProfileResult[companyIndex].data.name,
      companyId: searchCompanyProfileResult[companyIndex].companyId,
      branding: searchCompanyProfileResult[companyIndex].data.branding,
      companyProfilePublished:
        searchCompanyProfileResult[companyIndex].data.published,
    });

    setCompanySelectionIsValidated(true);
    setShowCompaniesListPage(false);

    trackClickCompanyInterceptSelection({
      config,
      context: reviewFormContext,
      selectedCompanyId: searchCompanyProfileResult[companyIndex].companyId,
      cardPosition: companyIndex,
    });
  };

  const handleOnDisambiguationBackClick = () => {
    setShowCompaniesListPage(false);

    trackClickCompanyInterceptReviewDifferentCompany({
      config,
      context: reviewFormContext,
    });
  };

  useEffect(() => {
    trackWriteAReviewStarted({ config, context: reviewFormContext });
  }, []);

  useEffect(() => {
    if (companyNameQueryParam) {
      setLoading(true);
      searchCompanyProfiles(companyNameQueryParam).then((profiles) => {
        const isOrphanMatch =
          profiles.length === 1 &&
          profiles[0].companyId === ORPHAN_REVIEW_COMPANY_ID;
        const exactCompanyMatch = profiles.find(
          (profile) =>
            profile.data.name === companyNameQueryParam &&
            profile.companyId !== ORPHAN_REVIEW_COMPANY_ID,
        );

        if (!profiles || profiles.length === 0) {
          setShowCompaniesListPage(false);
        } else if (exactCompanyMatch && !isOrphanMatch) {
          setReview({
            companyName: exactCompanyMatch.data.name,
            companyId: exactCompanyMatch.companyId,
            branding: exactCompanyMatch.data.branding,
            companyProfilePublished: exactCompanyMatch.data.published,
          });
          setCompanySelectionIsValidated(true);
          setShowCompaniesListPage(false);
        } else {
          setSearchCompanyProfileResult(profiles);
          setShowCompaniesListPage(true);
        }

        setLoading(false);
      });
    }
  }, [companyNameQueryParam]);

  // Prevent users from accidentally leaving the page
  useEffect(() => {
    if (page === Progress.SubmitSuccess) return;

    function beforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
    }

    window.addEventListener('beforeunload', beforeUnload);

    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [page]);

  useEffect(() => {
    if (!review.companyName) {
      return;
    }
    getConfirmedRoles().then((confirmedRoles) => {
      const currentRole = confirmedRoles.find(
        (role) =>
          role.company?.text.toLowerCase() ===
          review.companyName?.toLowerCase(),
      );

      if (currentRole) {
        setSelectedRole(currentRole);
        setReview({
          ...review,
          workHistoryFrom: currentRole.from,
          ...(currentRole.to
            ? { workHistoryTo: currentRole.to, isStillWorkHere: false }
            : { isStillWorkHere: true }),
          workHistory: {
            from: currentRole.from,
            to: currentRole.to,
          },
          jobTitleText: currentRole.title.text,
          roleId: currentRole.id,
          isWorkHistoryPrefilled: true,
        });
      } else {
        setReview({
          ...review,
          workHistoryFrom: undefined,
          workHistoryTo: undefined,
          workHistory: undefined,
          isStillWorkHere: false,
          jobTitleText: '',
          roleId: uuid(),
          isWorkHistoryPrefilled: false,
        });
      }
    });
  }, [review.companyName]);

  if (loading) {
    return <Loader />;
  }

  if (showCompaniesListPage) {
    return (
      <CompaniesListPage
        searchCompanyProfileResult={searchCompanyProfileResult}
        handleOnCompanyNameClick={handleOnCompanyNameClick}
        handleOnDisambiguationBackClick={handleOnDisambiguationBackClick}
        reviewFormContext={reviewFormContext}
      />
    );
  }

  return (
    <Box data={{ testid: 'write-a-review-page' }}>
      <MetaTag
        title={`${t('Write a review')} | ${brandName[config.brand]}`}
        description={t(
          'Your review will help someone else and it only takes a few minutes. All reviews are anonymous. We wont share your name, only details about your role.',
        )}
        shouldDisableIndexing={true}
      />
      {page === Progress.OverallRating && (
        <OverallRatingPage
          review={review}
          reviewFormContext={reviewFormContext}
          searchCompanyProfiles={onSearchCompanyProfiles}
          setPage={handleOnSetPage}
          setter={setReview}
          pageNumber={page}
          hideCompanyNameSearch={companySelectionIsValidated}
        />
      )}
      {page === Progress.YourRole && (
        <YourRolePage
          review={review}
          reviewFormContext={reviewFormContext}
          searchLocations={onSearchLocations}
          setPage={handleOnSetPage}
          setter={setReview}
          getRoleTitles={getRoleTitles}
          pageNumber={page}
          onUpdateRole={onUpdateRole}
        />
      )}
      {page === Progress.YourExperience && (
        <YourExperiencePage
          review={review}
          reviewFormContext={reviewFormContext}
          setPage={handleOnSetPage}
          setter={setReview}
          pageNumber={page}
        />
      )}
      {page === Progress.YourSalary && (
        <YourSalaryPage
          review={review}
          reviewFormContext={reviewFormContext}
          setPage={handleOnSetPage}
          setter={setReview}
          pageNumber={page}
        />
      )}
      {page === Progress.YourFeedback && (
        <YourFeedbackPage
          review={review}
          reviewFormContext={reviewFormContext}
          setPage={handleOnSetPage}
          setter={setReview}
          onSubmit={handleOnSubmit}
          pageNumber={page}
          formErrors={getErrors()}
          isSubmitFailed={isSubmitFailed}
          isSubmitting={isSubmitting}
          onAlertClosed={() => setIsSubmitFailed(false)}
        />
      )}
      {page === Progress.SubmitSuccess && (
        <SubmitSuccessPage
          companyName={review.companyName!}
          reviewFormContext={reviewFormContext}
          companyId={
            review.companyId === ORPHAN_REVIEW_COMPANY_ID
              ? undefined
              : review.companyId
          }
          published={review.companyProfilePublished}
        />
      )}
    </Box>
  );
};
