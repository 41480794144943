/* eslint-disable @typescript-eslint/no-explicit-any */

export const removeTypename = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename);
  } else if (obj !== null && typeof obj === 'object') {
    const { __typename, ...rest } = obj;
    return Object.keys(rest).reduce(
      (acc, key) => {
        acc[key] = removeTypename(rest[key]);
        return acc;
      },
      {} as Record<string, any>,
    );
  }
  return obj;
};
