import { useConfig, useValidation, type Rules } from '@seek/libs-shared';
import { useTranslations } from '@vocab/react';
import { Stack } from 'braid-design-system';

import {
  trackClickYourSalaryPageContinue,
  trackClickYourSalarySubSection,
  trackValidationErrorYourSalaryPage,
} from '../../shared/tracking/writeAReview/trackingEvents';

import { ReviewPageLayout } from '../components/ReviewPageLayout/ReviewPageLayout';
import { SalaryQuestion } from '../questions/GroupedQuestions/SalaryQuestions/SalaryQuestion';
import { RateYourSalaryQuestion } from '../questions/SegmentedButtonQuestions/RateYourSalaryQuestion';
import { SalaryTypeQuestion } from '../questions/SegmentedButtonQuestions/SalaryTypeQuestion';
import type {
  PageProps,
  ReviewPayload,
  SalaryRating,
  SalaryType,
} from '../type';
import { validationRules } from '../validation/validationRules';

import { mapErrorMessage } from '../utils/mapErrorMessage';
import translations from './.vocab';

export const YourSalaryPage = ({
  review,
  reviewFormContext,
  setPage,
  setter,
  pageNumber,
}: PageProps) => {
  const { t } = useTranslations(translations);
  const config = useConfig();

  const { salary, salaryRating, salaryType } = validationRules(config.language);

  const fields = {
    salary: review?.salary,
    salaryRating: review?.salaryRating,
    salaryType: review?.salaryType,
  };

  const rules = {
    salary,
    salaryRating,
    salaryType,
  } as Rules<typeof fields>;

  const {
    validateWithErrors,
    getTone,
    getMessage,
    handleValueChangeWithValidation,
  } = useValidation<ReviewPayload>(fields, rules);

  const handleOnContinue = () => {
    const { isValid, errors } = validateWithErrors();

    trackClickYourSalaryPageContinue({
      config,
      context: reviewFormContext,
      validation: isValid ? 'success' : 'mandatory_questions_not_answered',
    });

    if (!isValid) {
      trackValidationErrorYourSalaryPage({
        config,
        context: reviewFormContext,
        errorMessage: errors.map((error) => mapErrorMessage(error.fieldName)),
        errorText: errors.map((error) => error.message),
      });
      return;
    }
    setPage(pageNumber + 1);
  };

  const handleOnBack = () => {
    setPage(pageNumber - 1);
  };

  const handleSalaryTypeChange = (value: SalaryType) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'salaryType',
      previousValue: review,
    })(value);

    setter((prev) => ({
      ...prev,
      salary: {
        ...prev.salary,
        minimum: undefined,
        maximum: undefined,
      },
    }));

    trackClickYourSalarySubSection({
      config,
      context: reviewFormContext,
      currentPageSubsection: 'pay_frequency',
    });
  };

  const handleSalaryRatingChange = (value: SalaryRating) => {
    handleValueChangeWithValidation({
      setter,
      validatorProperty: 'salaryRating',
      previousValue: review,
    })(value);

    trackClickYourSalarySubSection({
      config,
      context: reviewFormContext,
      currentPageSubsection: 'salary_rating',
    });
  };

  const handleSalaryChange = () => {
    trackClickYourSalarySubSection({
      context: reviewFormContext,
      config,
      currentPageSubsection: 'salary_amount',
    });
  };

  const handleBonusChange = () => {
    trackClickYourSalarySubSection({
      context: reviewFormContext,
      config,
      currentPageSubsection: 'bonus',
    });
  };

  return (
    <ReviewPageLayout
      progress={pageNumber}
      companyName={review?.companyName}
      subtitle={t(
        'Any salary information you share wont be tied to your review. It will only be used to calculate averages.',
      )}
      onContinue={handleOnContinue}
      onBack={handleOnBack}
      testid="your-salary-page"
    >
      <Stack space="small">
        <RateYourSalaryQuestion
          onChange={handleSalaryRatingChange}
          tone={getTone('salaryRating')}
          message={getMessage('salaryRating')}
          value={review?.salaryRating}
        />
        <SalaryTypeQuestion
          onChange={handleSalaryTypeChange}
          tone={getTone('salaryType')}
          message={getMessage('salaryType')}
          value={review?.salaryType}
        />
        <SalaryQuestion
          onChange={handleValueChangeWithValidation({
            setter,
            validatorProperty: 'salary',
            previousValue: review,
          })}
          onBonusChange={handleBonusChange}
          onSalaryChange={handleSalaryChange}
          reviewFormContext={reviewFormContext}
          salaryType={review?.salaryType}
          tone={getTone('salary')}
          message={getMessage('salary')}
          value={review?.salary}
        />
      </Stack>
    </ReviewPageLayout>
  );
};
