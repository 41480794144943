import { useTranslations } from '@vocab/react';
import {
  Badge,
  Box,
  Column,
  Columns,
  Heading,
  IconAdd,
  IconEdit,
  Inline,
  Loader,
  Notice,
  Stack,
  Text,
  TextLinkButton,
} from 'braid-design-system';

import type { ReactNode, RefObject } from 'react';
import translations from './.vocab';

type Padding = 'xsmall' | 'small' | 'medium' | 'large' | 'none';

interface Props extends React.PropsWithChildren {
  mode?: 'view' | 'edit';
  heading?: string | ReactNode;
  headingBetaBadge?: boolean;
  headingDescription?: string;
  /**
   * Only used for 'edit' mode
   */
  dataEmpty?: boolean;
  /**
   * Only used for 'edit' mode
   */
  onEditClick?: () => void;
  /**
   * Only used for 'edit' mode
   */
  addDataHint?: string;
  loading?: boolean;
  padding?: Padding;
  paddingX?: Padding;
  paddingY?: Padding;
  paddingBottom?: 'large' | 'none';
  background?: 'surface' | 'body';
}

function LargePaddingCard({
  children,
  ref,
  padding,
  paddingBottom,
  background,
}: {
  children: ReactNode;
  ref?: RefObject<HTMLElement>;
  padding: Padding;
  paddingBottom: Padding;
  background: 'surface' | 'body';
}) {
  return (
    <Box
      background={background}
      paddingX={['none', 'none', padding]}
      paddingTop={padding}
      paddingBottom={paddingBottom}
      ref={ref}
    >
      {children}
    </Box>
  );
}

export function Section({
  mode = 'view',
  loading = false,
  heading,
  headingBetaBadge,
  headingDescription,
  children,
  onEditClick,
  dataEmpty,
  addDataHint,
  padding = 'large',
  paddingX = 'none',
  paddingY = 'none',
  paddingBottom = 'large',
  background = 'surface',
}: Props) {
  const { t } = useTranslations(translations);

  const editMode = mode === 'edit';
  const universalPadding =
    paddingX === 'none' && paddingY === 'none' ? padding : 'none';

  return (
    <LargePaddingCard
      padding={mode == 'edit' ? 'none' : universalPadding}
      paddingBottom={paddingBottom}
      background={background}
    >
      <Stack space="large">
        {heading ? (
          <Columns space="large" alignY="center">
            <Column>
              <Stack space="small">
                <Heading level="3" component="h2">
                  {heading}
                  {headingBetaBadge && (
                    <>
                      {' '}
                      <Badge tone="promote">{t('Beta')}</Badge>
                    </>
                  )}
                </Heading>
                {headingDescription ? (
                  <Text tone="secondary" size="xsmall">
                    {headingDescription}
                  </Text>
                ) : null}
              </Stack>
            </Column>
            {!loading && editMode ? (
              <Column width="content">
                <Inline space="small">
                  <Text>
                    <TextLinkButton
                      icon={dataEmpty ? <IconAdd /> : <IconEdit />}
                      onClick={onEditClick}
                    >
                      {dataEmpty ? t('Add') : t('Edit')}
                    </TextLinkButton>
                  </Text>
                </Inline>
              </Column>
            ) : null}
          </Columns>
        ) : null}
        {loading ? <Loader delayVisibility /> : null}
        {!loading && editMode && dataEmpty ? (
          <Stack space="large">
            <Notice tone="info">
              <Text>{addDataHint}</Text>
            </Notice>
          </Stack>
        ) : (
          <Box>{children}</Box>
        )}
      </Stack>
    </LargePaddingCard>
  );
}
