import { gql } from '@apollo/client';

export const GET_COMPANY_PROFILE_QUERY = gql`
  query CompanyProfile($companyProfileId: ID!, $zone: Zone!, $locale: Locale!) {
    companyProfile(
      id: $companyProfileId
      zone: $zone
      # Always set useCachedProfile: false
      # https://seekchat.slack.com/archives/C061PRHBNS0/p1715239276565169?thread_ts=1714447134.751619&cid=C061PRHBNS0
      useCachedProfile: false
    ) {
      overview {
        description {
          full
          paragraphs
        }
        industry
        primaryLocation
        size {
          description(locale: $locale)
        }
        specialities
        website {
          url
          displayName
        }
      }
      awards {
        imageUrl
        name
        year
      }
      branding {
        logo
        cover {
          aspectRatio
          url
        }
      }
      cultureAndValues {
        values {
          title
          description
        }
        title
        imageUrl
        description
      }
      customStatement {
        title
        description {
          full
          paragraphs
        }
      }
      featured {
        title
        videoUrl
        imageUrl
        description {
          full
          paragraphs
        }
      }
      id
      isCompanyProfile
      missionStatement {
        description {
          paragraphs
          full
        }
      }
      name
      perksAndBenefits {
        title
        description
      }
      reviewsSummary {
        salaryRating {
          value
          rating
          description
        }
        ratings {
          rating
          description
        }
        overallRating {
          numberOfReviews {
            value
            description(locale: $locale)
          }
          value
        }
        employeeRecommendation {
          description
          rating
          value
        }
        communityGuideline {
          url
        }
      }
      tracking {
        isCompanyProfile
      }
    }
  }
`;
export const GET_COMPANY_NAME_SLUG_QUERY = gql`
  query CompanyNameSlug(
    $companySlug: String!
    $zone: Zone!
    $isLegacyUrl: Boolean!
  ) {
    companyNameSlug(
      companySlug: $companySlug
      zone: $zone
      isLegacyUrl: $isLegacyUrl
    ) {
      companySlug
      status
    }
  }
`;

export const GET_COMPANY_REVIEW_UPVOTES_QUERY = gql`
  query CompanyProfileReviewUpvotes(
    $companyProfileReviewUpvotesId: ID!
    $zone: Zone!
  ) {
    companyProfileReviewUpvotes(
      id: $companyProfileReviewUpvotesId
      zone: $zone
    ) {
      upvotes
    }
  }
`;

export const POST_COMPANY_REVIEW_UPVOTE_MUTATE = gql`
  mutation PostCompanyProfileReviewUpvotes(
    $input: PostCompanyProfileReviewUpvotesInput!
  ) {
    postCompanyProfileReviewUpvotes(input: $input) {
      success
    }
  }
`;

export const POST_COMPANY_REVIEW_FLAG_MUTATE = gql`
  mutation PostCompanyProfileReviewFlag(
    $input: PostCompanyProfileReviewFlagInput!
  ) {
    postCompanyProfileReviewFlag(input: $input) {
      success
    }
  }
`;

/*  Company Reviews */
export const GET_REVIEWS_QUERY = gql`
  query CompanyReviews(
    $companyProfileId: ID!
    $zone: Zone!
    $page: Int
    $sort: String
    $perPage: Int
    $locationId: ID
  ) {
    companyProfile(id: $companyProfileId, zone: $zone) {
      branding {
        logo
      }
      reviewsSummary {
        overallRating {
          value
          numberOfReviews {
            value
          }
        }
      }
    }
    companyReviews(
      companyId: $companyProfileId
      zone: $zone
      page: $page
      sort: $sort
      perPage: $perPage
      locationId: $locationId
    ) {
      data {
        companyId
        pros
        cons
        jobTitle
        title
        id
        workLocation
        upvotes {
          count
          description
        }
        salarySummary
        employmentStatus
        recommended {
          description
          value
        }
        ratings {
          rating
          description
        }
        overallRating
        createdAt {
          shortAbsoluteLabel
        }
        reply {
          createdAt {
            dateTime
          }
          from
          userId
          text
        }
      }
      paging {
        page
        perPage
        total
        totalPages
      }
    }
  }
`;

export const GET_FEATURE_FLAGS_QUERY = gql`
  query CompanyProfilesFeatureFlags($input: FeatureFlagInput) {
    companyProfilesFeatureFlags(input: $input) {
      key
      value
    }
  }
`;

export const GET_GROUPED_COMPANIES_QUERY = gql`
  query GroupedCompanies(
    $groupBy: CompanyGroupByInput!
    $perPage: Int
    $sortBy: COMPANY_SORT_BY
    $randomSeed: String
    $zone: Zone!
    $excludedCompanyIds: [ID!]
  ) {
    groupedCompanies(
      groupBy: $groupBy
      perPage: $perPage
      sortBy: $sortBy
      randomSeed: $randomSeed
      zone: $zone
      excludedCompanyIds: $excludedCompanyIds
    ) {
      companies {
        id
        name
        slug
      }
      groupName
    }
  }
`;

export const GET_COMPANY_PROFILES_BY_NAME_QUERY = gql`
  query SearchCompanies($name: String!, $includeBranding: Boolean!) {
    searchCompanyProfilesByName(
      name: $name
      includeBranding: $includeBranding
    ) {
      companyId
      data {
        name
        branding {
          logo
        }
        published
      }
      organisationId
      zone
    }
  }
`;
